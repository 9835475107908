import { useContext } from "react";
import styles from "./Header.module.css";
import { NavLink, Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { LayoutContext } from "../../context/LayoutContext";
import { ReactComponent as ExpandIcon } from "../../assets/icons/expand.svg";
import triangle from "../../assets/KROHNE_triangle.png";

const SectionLeft = ({ layout }) => {
  const { mobileMenu, setMobileMenu, setShowRecordPanel } =
    useContext(LayoutContext);
  const { user } = useContext(UserContext);

  const handleLinkClick = () => {
    setShowRecordPanel(false);
  };

  function handleMobileMenu() {
    setMobileMenu((prev) => !prev);
  }

  return (
    <>
      <div className={styles.leftPart}>
        <div className={styles.logoContainer}>
          <img
            className={styles.triangle}
            src={triangle}
            alt="KROHNE triangle logo"
          />
          <Link to="/" className={styles.logo}>
            mySales
          </Link>
        </div>
      </div>
      {
        // do not show the menu if user role is gid:
        !["gid"].includes(user.role[0]) && (
          <nav className={styles.mainNav}>
            <ul className={styles.menu}>
              {!layout.smallScreen && (
                <>
                  <NavLink
                    to="/dashboard"
                    className={styles.menuItem}
                    activeClassName={styles.menuItemActive}
                    key="charts"
                    onClick={handleLinkClick}
                  >
                    Dashboard
                  </NavLink>

                  <NavLink
                    exact
                    to="/order-income"
                    className={styles.menuItem}
                    activeClassName={styles.menuItemActive}
                    key="order-income"
                    onClick={handleLinkClick}
                  >
                    Order Income
                  </NavLink>

                  <NavLink
                    to="/large-orders"
                    className={styles.menuItem}
                    activeClassName={styles.menuItemActive}
                    key="large-orders"
                    onClick={handleLinkClick}
                  >
                    Large Order Potential
                  </NavLink>
                  {["admin", "rsm"].includes(user.role[0]) && (
                    <NavLink
                      to="/annual-budget"
                      className={styles.menuItem}
                      activeClassName={styles.menuItemActive}
                      key="/annual-budget"
                      onClick={handleLinkClick}
                    >
                      Annual Budget
                    </NavLink>
                  )}
                  <NavLink
                    to="/sync"
                    className={styles.menuItem}
                    activeClassName={styles.menuItemActive}
                    key="sync"
                    onClick={handleLinkClick}
                  >
                    Sync
                  </NavLink>
                </>
              )}

              {(["basic"].includes(user.role[0]) && layout.smallScreen) ||
              ["admin", "rsm"].includes(user.role[0]) ? (
                <div
                  className={styles.menuItem}
                  style={{
                    alignItems: "center",
                  }}
                  key="/more"
                  onClick={handleMobileMenu}
                >
                  {layout.smallScreen ? "Menu" : "More"} <ExpandIcon />
                </div>
              ) : null}

              {mobileMenu && (
                <ul
                  className={styles.secondaryMenuBox}
                  onMouseEnter={() => setMobileMenu(true)}
                  onMouseLeave={() => setMobileMenu(false)}
                >
                  {layout.smallScreen && (
                    <>
                      <NavLink
                        to="/dashboard"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="charts"
                        onClick={handleLinkClick}
                      >
                        Dashboard
                      </NavLink>
                      <NavLink
                        exact
                        to="/order-income"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="order-income"
                        onClick={handleLinkClick}
                      >
                        Order Income
                      </NavLink>
                      <NavLink
                        to="/large-orders"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="large-orders"
                        onClick={handleLinkClick}
                      >
                        Large Order Potential
                      </NavLink>{" "}
                      {["admin", "rsm"].includes(user.role[0]) && (
                        <NavLink
                          to="/annual-budget"
                          className={styles.menuItem}
                          activeClassName={styles.menuItemActive}
                          key="/annual-budget"
                          onClick={handleLinkClick}
                        >
                          Annual Budget
                        </NavLink>
                      )}
                      <NavLink
                        to="/sync"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="sync"
                        onClick={handleLinkClick}
                      >
                        Sync
                      </NavLink>
                      {["admin", "rsm"].includes(user.role[0]) && <hr />}
                    </>
                  )}
                  {["admin", "rsm"].includes(user.role[0]) && (
                    <NavLink
                      to="/users"
                      className={styles.menuItem}
                      activeClassName={styles.menuItemActive}
                      key="/users"
                      onClick={handleLinkClick}
                    >
                      Users
                    </NavLink>
                  )}
                  {["admin"].includes(user.role[0]) && (
                    <>
                      <NavLink
                        to="/lso"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="/lso"
                        onClick={handleLinkClick}
                      >
                        Accounting Entity
                      </NavLink>
                      <NavLink
                        to="/gid"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="/gid"
                        onClick={handleLinkClick}
                      >
                        Global Industries
                      </NavLink>
                      <NavLink
                        to="/sales-regions"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="/sales-regions"
                        onClick={handleLinkClick}
                      >
                        Sales Regions
                      </NavLink>
                      <NavLink
                        to="/sub-regions"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="/sub-regions"
                        onClick={handleLinkClick}
                      >
                        Sub-Regions
                      </NavLink>
                      <NavLink
                        to="/territory"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="/territory"
                        onClick={handleLinkClick}
                      >
                        Territories
                      </NavLink>
                      <NavLink
                        to="/countries"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="/countries"
                        onClick={handleLinkClick}
                      >
                        Countries
                      </NavLink>
                      <NavLink
                        to="/win-probability"
                        className={styles.menuItem}
                        activeClassName={styles.menuItemActive}
                        key="/win-probability"
                        onClick={handleLinkClick}
                      >
                        Win Probabilities
                      </NavLink>
                    </>
                  )}
                </ul>
              )}
            </ul>
          </nav>
        )
      }
    </>
  );
};

export default SectionLeft;
