export const Loading = ({ text }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p
        style={{
          fontWeight: 500,
          fontSize: 40,
          color: "darkgray",
          filter: "drop-shadow(1px 1px 2px rgba(255, 255, 255, 0.5))",
        }}
      >
        {text}
      </p>
    </div>
  );
};
