import { useState, useEffect } from "react";

function useCheckMediaQuery(query) {
  let [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    let media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
  }, [query, matches]);

  return matches;
}

export default useCheckMediaQuery;

// how to use in components:
// example let large = useCheckMediaQuery("(min-width: 800px)");
