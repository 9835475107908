import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import { LayoutContext } from "../../context/LayoutContext";
import { UserContext } from "../../context/UserContext";
import ExportData from "../ExportData";
import styles from "./Header.module.css";
import SectionCenter from "./SectionCenter";
import SectionLeft from "./SectionLeft";
import SectionRight from "./SectionRight";

const Header = () => {
  const { user } = useContext(UserContext);
  const userRole = user.role[0];
  const { pathname } = useLocation();
  const {
    settingsBox,
    setSettingsBox,
    addNewDropdown,
    setAddNewDropdown,
    setHeaderHeight,
    mobileMenu,
    setMobileMenu,
  } = useContext(LayoutContext);
  const [isModalOpen, setIsModalOpen] = useState(false); // default false
  const headerRef = useRef();
  const sectionLeftRef = useRef();
  const sectionCenterRef = useRef();
  const sectionRightRef = useRef();

  function handleCloseNavBoxes() {
    if (mobileMenu) {
      setMobileMenu(false);
    }
    if (settingsBox) {
      setSettingsBox(false);
    }
    if (addNewDropdown) {
      setAddNewDropdown(false);
    }
  }

  const [layout, setLayout] = useState({
    smallScreen: false,
    switchSelectBoxes: false,
  });

  useEffect(() => {
    // get the height of the header to calculate the height of the table:
    const headerHeight = headerRef.current?.offsetHeight;
    setHeaderHeight(headerHeight);
  });

  useEffect(() => {
    const savedFullMenuSize = sectionLeftRef.current.offsetWidth; // store this value for calculation of small menu vs. big menu
    const savedSelectBoxesSize = sectionCenterRef.current.offsetWidth; // store this value for calculation of small menu vs. big menu
    const refCurrent = headerRef.current || null;

    const resizeObserver = new ResizeObserver((entries) => {
      let shouldSelectBoxesSwitch =
        savedSelectBoxesSize > 0 &&
        refCurrent?.offsetWidth - savedFullMenuSize <=
          savedSelectBoxesSize +
            sectionRightRef.current?.offsetWidth +
            40 +
            1.111
          ? true
          : false;

      let displaysmallScreen =
        refCurrent?.offsetWidth -
          savedFullMenuSize -
          sectionRightRef.current?.offsetWidth <=
        65;

      sectionLeftRef &&
        setLayout(() => ({
          switchSelectBoxes: shouldSelectBoxesSwitch,
          smallScreen: displaysmallScreen,
        }));
    });

    resizeObserver.observe(refCurrent);
    return () => refCurrent && resizeObserver.unobserve(refCurrent);
  }, [pathname]);

  // Rules for displaying LSO / GID dropdown select fields in the HEADER of app:
  const shouldLSOFieldBeVisibile =
    ["/dashboard", "/order-income", "/large-orders", "/annual-budget"].includes(
      pathname
    ) && !["gid"].includes(userRole);

  const shouldGIDFieldBeVisibile =
    ["/large-orders"].includes(pathname) && ["gid"].includes(userRole);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <header
        onClick={handleCloseNavBoxes}
        className={styles.header}
        ref={headerRef}
        style={{
          flexWrap: layout.switchSelectBoxes ? "wrap" : "nowrap",
        }}
      >
        {isModalOpen && (
          <ExportData
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}

        <section
          // Logo & Navigation
          ref={sectionLeftRef}
          className={`${styles.section} ${styles.sectionLeft}`}
        >
          <SectionLeft layout={layout} />
        </section>

        <section
          // Selection fields for LSO & GID
          ref={sectionCenterRef}
          className={styles.sectionCenter}
          style={{
            padding: 0,
            order: layout.switchSelectBoxes ? 3 : 2,
            width: layout.switchSelectBoxes ? "100%" : "auto",
          }}
        >
          <SectionCenter
            isLSOSelectFieldVisible={shouldLSOFieldBeVisibile}
            isGIDSelectFieldVisible={shouldGIDFieldBeVisibile}
            layout={layout}
          />
        </section>

        <section
          // Add New Button & Settings Button
          ref={sectionRightRef}
          className={`${styles.section} ${styles.sectionRight}`}
          style={{
            order: layout.switchSelectBoxes ? 2 : 3,
          }}
        >
          <SectionRight setIsModalOpen={setIsModalOpen} />
        </section>
      </header>
    </>
  );
};

export default Header;
