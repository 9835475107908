import { memo, useContext, useState, useEffect } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import "react-toastify/dist/ReactToastify.css";

import RecordPanelOrderIncome from "./RecordPanelOrderIncome";
import RecordPanelLargeOrders from "./RecordPanelLargeOrders";
import RecordPanelAnnualBudget from "./RecordPanelAnnualBudget";
import RecordPanelLSO from "./RecordPanelLSO";
import RecordPanelUsers from "./RecordPanelUsers";
import RecordPanelGID from "./RecordPanelGID";
import RecordPanelSalesRegion from "./RecordPanelSalesRegion";
import RecordPanelSubRegion from "./RecordPanelSubRegion";
import RecordPanelTerritory from "./RecordPanelTerritory";
import RecordPanelSync from "./RecordPanelSync";
import { useHistory, useLocation } from "react-router-dom";

export function useCloseRecordPanel() {
  const [closePanel, setClosePanel] = useState(false);
  const { setShowRecordPanel, setRecordPanelData, setRecordPanelType } =
    useContext(LayoutContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (closePanel) {
        history.push(location.pathname);
        setShowRecordPanel(false);
        setRecordPanelType("");
        setRecordPanelData("");
    }
  }, [closePanel]);

  return {
    closePanel: () => {
      setClosePanel(true);
    },
  };
}

const RecordPanelRouter = memo(() => {
  const { recordPanelType } = useContext(LayoutContext);

  // if clicked the button "NEW" show panel inputs based on current page:
  // if clicked edit table row buttons (update mode):

  // render content based on
  switch (recordPanelType) {
    case "/order-income":
      return <RecordPanelOrderIncome />;
    case "/large-orders":
      return <RecordPanelLargeOrders />;
    case "/annual-budget":
      return <RecordPanelAnnualBudget />;
    case "/lso":
      return <RecordPanelLSO />;
    case "/users":
      return <RecordPanelUsers />;
    case "/gid":
      return <RecordPanelGID />;
    case "/sales-regions":
      return <RecordPanelSalesRegion />;
    case "/sub-regions":
      return <RecordPanelSubRegion />;
    case "/territory":
      return <RecordPanelTerritory />;
    case "/sync":
      return <RecordPanelSync />;
    default:
      return null;
  }
});

export default RecordPanelRouter;
