import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { ReactComponent as ExpandIcon } from "../../assets/icons/expand.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as ThresholdIcon } from "../../assets/icons/threshold.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import { ReactComponent as GroupingIcon } from "../../assets/icons/grouping.svg";
import { ReactComponent as FullScreenIcon } from "../../assets/icons/fullscreen.svg";
import { ReactComponent as FullScreenExitIcon } from "../../assets/icons/fullscreen_exit.svg";
import { ReactComponent as SyncIcon } from "../../assets/icons/sync.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/help.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as ChartViewIcon } from "../../assets/icons/chart_view.svg";
import { useState, useContext } from "react";
import styles from "./Header.module.css";
import { useLocation, Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { LayoutContext } from "../../context/LayoutContext";
import { toast } from "react-toastify";
import DB_URL from "../../environments";
import { sessionExpirationChecker } from "../../utils/fetch_helpers";
import packageJSON from "../../../package.json";
import { formatDistance } from "date-fns";
import { TempContext } from "../../context/TempContext";

const packageVersion = packageJSON.version;
const packageTime = new Date(packageJSON.time);

const SectionRight = ({ setIsModalOpen }) => {
  const {
    settingsBox,
    setSettingsBox,
    addNewDropdown,
    setAddNewDropdown,
    toggleShowFilters,
    showRecordPanel,
    setShowRecordPanel,
    setRecordPanelType,
    showTableGrouping,
    toggleTableGrouping,
    largeOrdersThreshold,
    setLargeOrdersThreshold,
  } = useContext(LayoutContext);
  const { 
    annualEOYForecastVariationsChartVisibility,
    setAnnualEOYForecastVariationsChartVisibility
  } = useContext(TempContext);
  const { user, logoutUser } = useContext(UserContext);
  const location = useLocation();
  const [fullScreen, setFullScreen] = useState(false);
  const { showFilters } = useContext(LayoutContext);

  const handleUserState = () => {
    logoutUser();
    setSettingsBox(false);
  };

  const toggleFullScreen = () => {
    setFullScreen((previous) => !previous);

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }

    setSettingsBox(false);
  };

  const handleSettingsBox = () => {
    setSettingsBox((prev) => !prev);
  };

  const handleAddNewPanel = () => {
    setShowRecordPanel(true);
    setAddNewDropdown(false);
    setRecordPanelType(location.pathname);
  };

  const handleAddNewDropdown = () => {
    setAddNewDropdown((prev) => !prev);
  };

  const handleShowFilters = () => {
    // handle tables column filters visibility
    toggleShowFilters();
    setSettingsBox(false); // close the settings box
  };

  const handleTableGrouping = () => {
    // handle tables column group visibility
    toggleTableGrouping();
    setSettingsBox(false); // close the settings box
  };

  function handleLargeOrdersThreshold() {
    // save a user input value as a large order threshold:
    let lastValue = largeOrdersThreshold || "";
    // let lastValue = localStorage.getItem("largeOrdersThreshold") || "";
    // return empty of user input from the prompt dialog:
    let value = prompt(
      "Type a number (bigger than 0) to set as Large Orders Threshold.\n\nOrder Values in new large orders records need to be equal or bigger than your chosen threshold.\n",
      lastValue
    );

    let valueToNumber = value ? Number(value) : null;

    if (isNaN(valueToNumber)) {
      // if value is NOT a number, do nothing:
    } else if (valueToNumber === null) {
      // if value is empty, do nothing:
    } else {
      // if number is negative, make it positive:
      if (Math.sign(valueToNumber) === -1) {
        valueToNumber *= -1;
      }
      setLargeOrdersThreshold(valueToNumber);

      async function setThreshold(value) {
        try {
          const response = await fetch(DB_URL + "/api/admin/setting/", {
            method: "PUT",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({
              property: "largeOrdersThreshold",
              value: value.toString(),
            }),
          });
          sessionExpirationChecker(response, logoutUser);
          toast.success("Large Orders Threshold updated to " + value);
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }

      setThreshold(valueToNumber);
    }
  }

  return (
    <>
      {
        // Add Button:
        // if user is gid do not show add new buttons at all:
        ["gid"].includes(user.role[0]) ? null : (
          // entire add new buttons component:
          <div
            style={{
              display: "flex",
              position: "relative",
            }}
          >
            {
              // add new entity of current page type
              // do not show current add new button for basic users:
              [
                "/dashboard",
                "/countries",
                "/win-probability",
                "/sync",
              ].includes(location.pathname) ? null : ["basic"].includes(
                  user.role[0]
                ) &&
                ["/annual-budget", "/users"].includes(
                  location.pathname
                ) ? null : (
                <button
                  disabled={showRecordPanel}
                  onClick={handleAddNewPanel}
                  className={styles.addBtn}
                >
                  <AddIcon style={{ fill: "currentColor" }} />
                  <span style={{ marginRight: "6px" }}>NEW</span>
                </button>
              )
            }

            {/* button to display the dropdown menu, with the logic below */}
            <button
              disabled={showRecordPanel}
              onClick={handleAddNewDropdown}
              className={
                ![
                  "/dashboard",
                  "/countries",
                  "/win-probability",
                  "/sync",
                ].includes(location.pathname)
                  ? ["basic"].includes(user.role[0]) &&
                    ["/annual-budget", "/users"].includes(location.pathname)
                    ? styles.addDropBtnAllEntities
                    : styles.addDropBtn
                  : styles.addDropBtnAllEntities
              }
            >
              <ExpandIcon style={{ fill: "currentColor" }} />
            </button>

            {
              // logic for the dropdown:
              addNewDropdown && (
                <ul
                  className={styles.menuBox}
                  style={{ top: 48 }}
                  onMouseEnter={() => setAddNewDropdown(true)}
                  onMouseLeave={() => setAddNewDropdown(false)}
                >
                  {location.pathname !== "/order-income" && (
                    <li
                      onClick={() => {
                        setAddNewDropdown(false);
                        setShowRecordPanel(true);
                        setRecordPanelType("/order-income");
                      }}
                    >
                      <p>New Order Income</p>
                    </li>
                  )}
                  {location.pathname !== "/large-orders" && (
                    <li
                      onClick={() => {
                        setAddNewDropdown(false);
                        setShowRecordPanel(true);
                        setRecordPanelType("/large-orders");
                      }}
                    >
                      <p>New Large Order Potential</p>
                    </li>
                  )}

                  {location.pathname !== "/annual-budget" &&
                    ["admin", "rsm"].includes(user.role[0]) && (
                      <li
                        onClick={() => {
                          setAddNewDropdown(false);
                          setShowRecordPanel(true);
                          setRecordPanelType("/annual-budget");
                        }}
                      >
                        <p>New Annual Budget</p>
                      </li>
                    )}

                  {["admin", "rsm"].includes(user.role[0]) && <hr />}

                  {location.pathname !== "/lso" &&
                    ["admin", "rsm"].includes(user.role[0]) && (
                      <li
                        onClick={() => {
                          setAddNewDropdown(false);
                          setShowRecordPanel(true);
                          setRecordPanelType("/lso");
                        }}
                      >
                        <p>New Accounting Entity</p>
                      </li>
                    )}

                  {location.pathname !== "/users" &&
                    ["admin", "rsm"].includes(user.role[0]) && (
                      <li
                        onClick={() => {
                          setAddNewDropdown(false);
                          setShowRecordPanel(true);
                          setRecordPanelType("/users");
                        }}
                      >
                        <p>New User</p>
                      </li>
                    )}

                  {location.pathname !== "/gid" &&
                    ["admin", "rsm"].includes(user.role[0]) && (
                      <li
                        onClick={() => {
                          setAddNewDropdown(false);
                          setShowRecordPanel(true);
                          setRecordPanelType("/gid");
                        }}
                      >
                        <p>New GID</p>
                      </li>
                    )}

                  {location.pathname !== "/sales-regions" &&
                    ["admin", "rsm"].includes(user.role[0]) && (
                      <li
                        onClick={() => {
                          setAddNewDropdown(false);
                          setShowRecordPanel(true);
                          setRecordPanelType("/sales-regions");
                        }}
                      >
                        <p>New Sales Region</p>
                      </li>
                    )}
                  {location.pathname !== "/sub-regions" &&
                    ["admin", "rsm"].includes(user.role[0]) && (
                      <li
                        onClick={() => {
                          setAddNewDropdown(false);
                          setShowRecordPanel(true);
                          setRecordPanelType("/sub-regions");
                        }}
                      >
                        <p>New Sub-Region</p>
                      </li>
                    )}
                  {location.pathname !== "/territory" &&
                    ["admin", "rsm"].includes(user.role[0]) && (
                      <li
                        onClick={() => {
                          setAddNewDropdown(false);
                          setShowRecordPanel(true);
                          setRecordPanelType("/territory");
                        }}
                      >
                        <p>New Territory</p>
                      </li>
                    )}
                </ul>
              )
            }
            {/* end of add new dropdown */}
          </div>
          //  end of the 'add new' component
        )
      }

      {/* Settings Menu: */}
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <button
          className={styles.iconBtn}
          title="Settings"
          onClick={handleSettingsBox}
        >
          <SettingsIcon />
        </button>
        {settingsBox && (
          <ul
            className={styles.settingsBox}
            onMouseEnter={() => setSettingsBox(true)}
            onMouseLeave={() => setSettingsBox(false)}
          >
            {location.pathname === "/large-orders" &&
              ["admin", "rsm"].includes(user.role[0]) && (
                <li onClick={handleLargeOrdersThreshold}>
                  <ThresholdIcon style={{ fill: "customColor" }} />
                  <p>Large Orders Threshold</p>
                </li>
              )}
            {location.pathname !== "/dashboard" && (
              <li onClick={handleShowFilters}>
                <FilterIcon style={{ fill: "customColor" }} />
                <p>{showFilters ? "Hide " : "Show "} Table Filters</p>
              </li>
            )}
            {location.pathname !== "/dashboard" && (
              <>
                <li onClick={handleTableGrouping}>
                  <GroupingIcon style={{ fill: "customColor" }} />
                  <p>{showTableGrouping ? "Hide " : "Show "} Grouping</p>
                </li>
                <hr />
              </>
            )}
            <li onClick={toggleFullScreen}>
              {fullScreen ? (
                <FullScreenExitIcon style={{ fill: "customColor" }} />
              ) : (
                <FullScreenIcon style={{ fill: "customColor" }} />
              )}
              <p>{fullScreen ? "Exit Full Screen" : "Full Screen"}</p>
            </li>
            {location.pathname === "/dashboard" && (
              <>
                <li
                  onClick={() =>
                    setAnnualEOYForecastVariationsChartVisibility(
                      (prev) => !prev
                    )
                  }
                >
                  <ChartViewIcon style={{ fill: "customColor" }} />
                  <p>
                    {annualEOYForecastVariationsChartVisibility
                      ? "Hide "
                      : "Show "}
                    Annual EOY chart
                  </p>
                </li>
              </>
            )}
            <hr />
            {["gid"].includes(user.role[0]) && (
              <>
                <Link to="/sync" className={styles.liLink}>
                  <SyncIcon style={{ fill: "customColor" }} /> <p>Sync</p>
                </Link>
                <hr />
              </>
            )}
            <li
              onClick={() => {
                if (user) {
                  alert(
                    // `YOUR ACCOUNT INFO \n\nusername: ${user.userName}\nrole: ${user.role[0]}\nid: ${user.id}\n\ntoken: ${user.token}`
                    `YOUR INFO:\n\nusername: ${user.userName}\nrole: ${user.role[0]}\n`
                  );
                }
              }}
            >
              <ProfileIcon style={{ fill: "customColor" }} />
              <p>My Profile</p>
            </li>
            {/* <li
                style={{ color: "lightgray" }}
                onClick={() => alert("User Logs feature is not available yet.")}
              >
                <span className="material-icons">history</span>
                <p>User Logs</p>
              </li> */}
            {["admin", "rsm"].includes(user.role[0]) && (
              <li
                // style={{ color: "lightgray" }}
                onClick={() => setIsModalOpen(true)}
              >
                <DownloadIcon style={{ fill: "customColor" }} />
                <p>Export Data</p>
              </li>
            )}
            <hr />
            <li
              onClick={() =>
                window.open(
                  "https://krohnegroup.sharepoint.com/sites/mySaleswebapp/help/",
                  "_blank"
                )
              }
            >
              <HelpIcon style={{ fill: "customColor" }} />
              <p>Help & Tutorials</p>
            </li>
            <hr />
            <li onClick={handleUserState}>
              <LogoutIcon style={{ fill: "customColor" }} />
              <p>Sign Out</p>
            </li>
            <hr />
            {packageVersion && (
              <div
                style={{
                  padding: "2px 10px 6px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <p style={{ color: "gray", fontWeight: 400, margin: 0 }}>
                  Version: {packageVersion}
                </p>
                {packageTime && (
                  <p
                    style={{
                      color: "gray",
                      fontWeight: 400,
                      fontSize: ".8rem",
                      margin: 0,
                    }}
                    title={`Last update: ${packageTime.toDateString()}, ${packageTime.toLocaleTimeString()} `}
                  >
                    Last update:{" "}
                    {formatDistance(packageTime, new Date(), {
                      includeSeconds: true,
                      addSuffix: true,
                    })}
                  </p>
                )}
              </div>
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export default SectionRight;
