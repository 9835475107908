import { useState, useEffect, useContext, useMemo } from "react";
import styles from "./Header.module.css";
import { UserContext } from "../../context/UserContext";
import { LayoutContext } from "../../context/LayoutContext";
import Select from "react-select";
import DB_URL from "../../environments";
import { sessionExpirationChecker } from "../../utils/fetch_helpers";
import { useLocation } from "react-router-dom";
import {
  LsoEntities,
  extractIdNumberFromSelectedOption,
  mapResultsToSelectionOptions,
  yearRange,
} from "./helpers";

const SectionCenter = ({
  isLSOSelectFieldVisible,
  isGIDSelectFieldVisible,
  layout,
}) => {
  const location = useLocation();
  const pathname = location.pathname;
  const {
    recordPanelData,
    globalSelectedLSO,
    setGlobalSelectedLSO,
    globalSelectedGID,
    setGlobalSelectedGID,
    setShouldTableRefresh,
    preferredFilterValues,
    setPreferredFilterValues,
    LSOselectedOption,
    setLSOSelectedOption,
  } = useContext(LayoutContext);
  const { user, logoutUser } = useContext(UserContext);
  const [lsoOptions, setLsoOptions] = useState(null);
  const [gidOptions, setGidOptions] = useState(null);
  const [salesRegionsOptions, setSalesRegionsOptions] = useState(null);
  const [subRegionsOptions, setSubRegionsOptions] = useState(null);
  const [countriesOptions, setCountriesOptions] = useState(null);

  const isPermittedToSeeExtraEntities =
    isLSOSelectFieldVisible &&
    ["/dashboard", "/order-income"].includes(pathname) &&
    ["admin", "rsm"].includes(user.role[0]);

  useEffect(() => {
    async function getLSOs() {
      try {
        let response = await fetch(
          DB_URL + "/api/admin/large-sales-organization/search",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({
              ignoreCase: true,
              key: "",
              pageNumber: 1,
              pageSize: 1,
              sortColumn: "",
              sortDirection: "ASC",
              unpaged: true,
            }),
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();

        if (result.content) {
          const lso_options = mapResultsToSelectionOptions(
            result.content,
            LsoEntities.LSO
          );
          setLsoOptions(lso_options);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    isLSOSelectFieldVisible ? getLSOs() : setLsoOptions(null);
  }, [
    user,
    recordPanelData,
    logoutUser,
    location,
    isLSOSelectFieldVisible,
    setLsoOptions,
  ]);

  useEffect(() => {
    async function getSalesRegions() {
      // ONLY ADMIN & RSM users should be allowed to fetch sales regions
      try {
        let response = await fetch(DB_URL + "/api/admin/sales-region/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
          body: JSON.stringify({
            ignoreCase: true,
            key: "",
            pageNumber: 1,
            pageSize: 1,
            sortColumn: "",
            sortDirection: "ASC",
            unpaged: true,
          }),
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result.content) {
          const modifiedResult = mapResultsToSelectionOptions(
            result.content,
            LsoEntities.SALES_REGION
          );
          setSalesRegionsOptions(modifiedResult);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    isPermittedToSeeExtraEntities
      ? getSalesRegions()
      : setSalesRegionsOptions(null);
  }, [
    user,
    recordPanelData,
    logoutUser,
    location,
    isLSOSelectFieldVisible,
    setSalesRegionsOptions,
  ]);

  useEffect(() => {
    async function getSubRegions() {
      // ONLY ADMIN & RSM users should be allowed to fetch sales regions
      try {
        let response = await fetch(DB_URL + "/api/admin/sub-region/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
          body: JSON.stringify({
            ignoreCase: true,
            key: "",
            pageNumber: 1,
            pageSize: 1,
            sortColumn: "",
            sortDirection: "ASC",
            unpaged: true,
          }),
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result.content) {
          const modifiedResult = mapResultsToSelectionOptions(
            result.content,
            LsoEntities.SUB_REGION
          );
          setSubRegionsOptions(modifiedResult);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    isPermittedToSeeExtraEntities
      ? getSubRegions()
      : setSubRegionsOptions(null);
  }, [
    user,
    recordPanelData,
    logoutUser,
    location,
    isLSOSelectFieldVisible,
    setSubRegionsOptions,
  ]);

  useEffect(() => {
    async function getCountries() {
      try {
        let response = await fetch(DB_URL + "/api/admin/country/list", {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const modifiedResult = mapResultsToSelectionOptions(
            result,
            LsoEntities.COUNTRY
          );
          setCountriesOptions(modifiedResult);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    isPermittedToSeeExtraEntities ? getCountries() : setCountriesOptions(null);
  }, [
    user,
    recordPanelData,
    logoutUser,
    location,
    isLSOSelectFieldVisible,
    setCountriesOptions,
  ]);

  useEffect(() => {
    // handle selecting option for LSO field
    const { COUNTRY, LSO, SALES_REGION, SUB_REGION } = LsoEntities;

    if (
      isPermittedToSeeExtraEntities &&
      LSOselectedOption &&
      [COUNTRY, LSO, SALES_REGION, SUB_REGION].includes(LSOselectedOption?.type)
    ) {
      return; // keep the same LSO option selected as previous if contition above
    }

    if (!lsoOptions) return;

    if (globalSelectedLSO) {
      const foundId = lsoOptions.find(
        (option) =>
          extractIdNumberFromSelectedOption(option) === globalSelectedLSO
      );
      if (foundId) {
        setLSOSelectedOption(foundId);
        return;
      }
    }

    // default selection first of the list  
    setLSOSelectedOption(lsoOptions[0]);
    setGlobalSelectedLSO(extractIdNumberFromSelectedOption(lsoOptions[0]))
  }, [
    pathname,
    lsoOptions,
    globalSelectedLSO,
    setLSOSelectedOption,
    extractIdNumberFromSelectedOption,
  ]);

  const adminDashboardRaw = [
    { id: 1, name: "All Data", styling: { bold: true } },
  ];

  const adminDashboardOptions = mapResultsToSelectionOptions(
    adminDashboardRaw,
    LsoEntities.ALL_DASHBOARD_DATA
  );

  const adminLargeOrdersRaw = [
    { id: 1, name: "All Accounting Entities", styling: { bold: true } },
  ];

  const adminLargeOrdersOptions = mapResultsToSelectionOptions(
    adminLargeOrdersRaw,
    LsoEntities.ALL_ACCOUNTING_ENTITIES
  );

  const lsoGroupedOptions = useMemo(() => {
    if (!isLSOSelectFieldVisible) return false;

    const options = [];

    if (
      adminDashboardOptions &&
      user.role[0] === "admin" &&
      ["/dashboard"].includes(pathname)
    ) {
      options.push({
        label: "",
        options: adminDashboardOptions,
      });
    }

    if (
      adminLargeOrdersOptions &&
      user.role[0] === "admin" &&
      ["/large-orders"].includes(pathname)
    ) {
      options.push({
        label: "",
        options: adminLargeOrdersOptions,
      });
    }

    if (salesRegionsOptions) {
      options.push({
        label: "Sales Regions",
        options: salesRegionsOptions,
      });
    }

    if (subRegionsOptions) {
      options.push({
        label: "Sub-Regions",
        options: subRegionsOptions,
      });
    }

    if (lsoOptions) {
      options.push({
        label: "Accounting Entities",
        options: lsoOptions,
      });
    }

    if (user.role[0] === "admin" && countriesOptions) {  //TODO: remove rule for admin when we have the correlation the countries to Sales Regions
      options.push({
        label: "Countries",
        options: countriesOptions,
      });
    }

    return options;
  }, [
    user.role,
    pathname,
    salesRegionsOptions,
    subRegionsOptions,
    lsoOptions,
    countriesOptions,
  ]);

  const handleChangeLSOOption = (option) => {
    setLSOSelectedOption(option);
    setShouldTableRefresh(new Date().getTime());

    const idNumber = extractIdNumberFromSelectedOption(option);

    if (option.type === LsoEntities.LSO) {
      JSON.stringify(localStorage.setItem("preferredLSO", idNumber));
      setGlobalSelectedLSO(idNumber);
    }
  };

  useEffect(() => {
    async function getGIDs() {
      try {
        let response = await fetch(
          DB_URL + "/api/admin/global-industry/search",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({
              ignoreCase: true,
              key: "",
              pageNumber: 1,
              pageSize: 1,
              sortColumn: "",
              sortDirection: "ASC",
              unpaged: true,
            }),
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();

        if (result) {
          const gid_options = mapResultsToSelectionOptions(result.content);
          setGidOptions(gid_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    isGIDSelectFieldVisible ? getGIDs() : setGidOptions(null);
  }, [
    user,
    logoutUser,
    location,
    isGIDSelectFieldVisible
  ]);

  useEffect(() => {
    if (!gidOptions) return;
    const global_selected_gid = gidOptions[0]?.value;
    setGlobalSelectedGID(global_selected_gid);
  }, [gidOptions]);

  return (
    <>
      {isLSOSelectFieldVisible && (
        <label
          className={
            LSOselectedOption ? styles.inputField : styles.inputField_skeleton
          }
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: layout?.smallScreen ? 8 : null,
            width: 335,
          }}
        >
          {/* <p
            style={{
              padding: "6px",
              margin: "0px",
              lineHeight: "18px",
              textAlign: "end",
            }}
          >Entity:</p> */}
          {lsoOptions && (
            <Select
              placeholder={"Choose Entity..."}
              id={"lso-selection-field"}
              name={"lso-selection-field"}
              styles={{
                input: () => ({
                  width: 280,
                }),
                option: (styles, { data }) => {
                  const isBold = data.bold;
                  return {
                    ...styles,
                    fontWeight: isBold ? 500 : "inherit",
                  };
                },
              }}
              options={lsoGroupedOptions}
              value={LSOselectedOption}
              onChange={handleChangeLSOOption}
            />
          )}
        </label>
      )}

      {isGIDSelectFieldVisible && (
        <>
          <label
            className={
              gidOptions ? styles.inputField : styles.inputField_skeleton
            }
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: layout.smallScreen ? 8 : null,
            }}
          >
            {gidOptions && <p style={{ padding: 6, margin: 0 }}>GID:</p>}
            {gidOptions && (
              <Select
                styles={{
                  input: () => ({
                    width: 280,
                  }),
                }}
                isClearable={false}
                options={gidOptions}
                id={"gid-selection"}
                name={"gid-selection"}
                value={
                  gidOptions
                    ? gidOptions.find(
                      (option) => option.value === globalSelectedGID
                    )
                    : ""
                }
                onChange={(option) => {
                  if (option?.value) {
                    setGlobalSelectedGID(option.value);
                  }
                  setShouldTableRefresh(new Date().getTime());
                }}
                placeholder={"All GIDs"}
              />
            )}
          </label>
        </>
      )}

      {pathname === "/dashboard" && (
        <label
          className={styles.sectionCenter_yearInputField}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: layout.smallScreen ? 8 : null,
          }}
        >
          <Select
            id="year"
            name="year"
            styles={{
              input: () => ({
                width: 80,
              }),
            }}
            defaultValue={yearRange[3]} //current year if only 7 elements are hardcoded
            options={yearRange}
            onChange={(e) => {
              setShouldTableRefresh(new Date().getTime());
              setPreferredFilterValues({
                ...preferredFilterValues,
                dashboard: {
                  ...preferredFilterValues.dashboard,
                  year: e.value,
                },
              });
            }}
          />
        </label>
      )}
    </>
  );
};

export default SectionCenter;
