import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/history.svg";
import { ReactComponent as MoveIcon } from "../../assets/icons/move.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { useContext, useState, useEffect } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import styles from "./RecordPanel.module.css";
import { Button } from "../../style/ComponentsLibrary";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCheckMediaQuery from "../../hooks/useCheckMediaQuery";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { UserContext } from "../../context/UserContext";
import { motion, AnimatePresence } from "framer-motion";
import DB_URL from "../../environments";
import {
  booleanOptions,
  monthsOptions,
  classificationOptions,
  phaseOptions,
} from "../../utils/fields_options";
import { sessionExpirationChecker } from "../../utils/fetch_helpers";
import { useMemo, useRef } from "react";
import { useCloseRecordPanel } from ".";

const browserLang = navigator.language;

function RecordPanelLargeOrders() {
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [countriesOptions, setCountriesOptions] = useState();
  const [gidOptions, setGidOptions] = useState();
  const [winProbabilityOptions, setWinProbabilityOptions] = useState();
  const [territoriesOptions, setTerritoriesOptions] = useState();
  const [lsos, setLsos] = useState();
  const { user, logoutUser } = useContext(UserContext);
  const { globalSelectedLSO } = useContext(LayoutContext);
  const label = "Large Order Potential";
  const sm = useCheckMediaQuery("(max-width: 440px)");
  const {
    setShowRecordPanel,
    recordPanelData,
    setRecordPanelData,
    setShowHistoryTable,
    setShouldTableRefresh,
    setSuccessMessage,
    largeOrdersThreshold,
    setLargeOrdersThreshold,
    setRecordPanelType,
  } = useContext(LayoutContext);
  const { closePanel } = useCloseRecordPanel();
  const defaultFieldsRef = useRef({
    // based on server api, no not modify the keys:
    accountName: "",
    classification: "NONE",
    countryId: "",
    discount: 0,
    discountedValue: "",
    globalIndustryId: "",
    lastModifiedDate: "",
    lastModifiedBy: "",
    lastModifiedByUser: "",
    month: "",
    mustWin: "NO",
    nonErpQuoteNumber: "",
    opportunityName: "",
    opportunityNumber: `mysales-${new Date().getTime()}`,
    orderValue: "",
    owner: "",
    phase: "",
    referenceA: "",
    remarks: "",
    territoryId: "",
    winProbabilityId: "",
    year: "",
  });

  const { current: defaultFields } = defaultFieldsRef;

  useEffect(() => {
    async function getCountries() {
      try {
        let response = await fetch(DB_URL + "/api/admin/country/list", {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const formik_country_options = result.map((country) => ({
            value: country.id,
            label: country.name,
          }));
          setCountriesOptions(formik_country_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    getCountries();
  }, [user, logoutUser]);

  useEffect(() => {
    async function getGIDs() {
      try {
        let response = await fetch(
          DB_URL + "/api/admin/global-industry/search",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({
              ignoreCase: true,
              key: "",
              pageNumber: 1,
              pageSize: 1,
              sortColumn: "",
              sortDirection: "ASC",
              unpaged: true,
            }),
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const gid_options = result.content.map((gid) => ({
            value: gid.id,
            label: gid.name,
          }));
          setGidOptions(gid_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    getGIDs();
  }, [user, logoutUser]);

  useEffect(() => {
    async function getLSOs() {
      try {
        let response = await fetch(
          DB_URL + "/api/admin/large-sales-organization/search",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({
              ignoreCase: true,
              key: "",
              pageNumber: 1,
              pageSize: 1,
              sortColumn: "",
              sortDirection: "ASC",
              unpaged: true,
            }),
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (response.ok) {
          setLsos(result.content);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    getLSOs();
  }, [user, logoutUser]);

  useEffect(() => {
    async function getTerritories() {
      try {
        let response = await fetch(DB_URL + "/api/admin/territory/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
          body: JSON.stringify({
            ignoreCase: true,
            key: "",
            pageNumber: 1,
            pageSize: 1,
            sortColumn: "",
            sortDirection: "ASC",
            unpaged: true,
          }),
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const formik_territory_options = result.content.map((territory) => ({
            value: territory.id,
            label: territory.name,
          }));
          setTerritoriesOptions(formik_territory_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    getTerritories();
  }, [user, logoutUser]);

  useEffect(() => {
    async function getWinProbabilities() {
      try {
        let response = await fetch(DB_URL + `/api/admin/win-probability/list`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();

        if (result) {
          const winProbability_options = result.map((item) => ({
            value: item.id,
            label: item.name,
          }));

          setWinProbabilityOptions(winProbability_options);
        } else {
          //
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    getWinProbabilities();
  }, [user, logoutUser]);

  useEffect(() => {
    async function getThreshold() {
      try {
        let response = await fetch(
          DB_URL + "/api/admin/setting/largeOrdersThreshold",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (response.ok) {
          setLargeOrdersThreshold(Number(result.value));
        } else {
          setLargeOrdersThreshold(0);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    getThreshold();
  }, [recordPanelData, user, setLargeOrdersThreshold, logoutUser]);

  const handleDelete = (e) => {
    // delete from database:
    e.preventDefault();
    async function deleteLargeOrder() {
      try {
        let response = await fetch(
          DB_URL + `/api/large-order/${recordPanelData["id"]}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response;

        if (response.ok) {
          setShouldTableRefresh(new Date().getTime());
          setRecordPanelData("");
          setShowRecordPanel(false);
          setSuccessMessage(
            `Record deleted at ${new Date().toLocaleTimeString()}`
          );
        } else {
          toast.error(result.message);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    deleteLargeOrder();
  };

  const validate = (values) => {
    let errors = {};

    if (!values.year) errors.year = "Required";
    if (!values.month) errors.month = "Required";
    if (values.orderValue === "" && !isSyncRecord) {
      errors.orderValue = "Type a number bigger then 0!";
    } else if (values.orderValue === 0 && !isSyncRecord) {
      errors.orderValue = "Value must be bigger then 0!";
    } else if (
      !recordPanelData &&
      largeOrdersThreshold &&
      values.orderValue < largeOrdersThreshold &&
      !isSyncRecord
    )
      errors.orderValue = "Value cannot be smaller then the threshold!";
    if (values.discount === "" || values.discount > 100 || values.discount < 0)
      errors.discount = "Value should be between 0-100!";
    if (!values.opportunityNumber && !isSyncRecord)
      errors.opportunityNumber = "Required";
    if (!values.opportunityName && !isSyncRecord)
      errors.opportunityName = "Required";
    if (!values.accountName && !isSyncRecord) errors.accountName = "Required";
    if (!values.countryId) errors.countryId = "Required";
    if (!values.territoryId && !isSyncRecord) errors.territoryId = "Required";
    if (!values.globalIndustryId && !isSyncRecord)
      errors.globalIndustryId = "Required";
    if (!values.winProbabilityId && !isSyncRecord)
      errors.winProbabilityId = "Required";
    if (!values.classification) errors.classification = "Required";

    return errors;
  };

  const submit = (values) => {
    // refactoring the values:
    const updateValues = { ...values };
    delete updateValues.lastModifiedDate;
    delete updateValues.lastModifiedByUser;
    delete updateValues.discountValue;

    // if edit row button is clicked (row data in saved in "recordPanelData"):
    if (recordPanelData) {
      setShowRecordPanel(false);
      // UPDATE record to db:
      async function updateLargeOrder() {
        try {
          let response = await fetch(
            DB_URL + `/api/large-order/${recordPanelData["id"]}/`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
              body: JSON.stringify(updateValues),
            }
          );
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${
                recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      updateLargeOrder();
    } else {
      setShowRecordPanel(false);
      // Create Record to db
      // add record to database:

      async function createLargeOrder() {
        try {
          let response = await fetch(DB_URL + "/api/large-order/", {
            method: "PUT",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify(updateValues),
          });
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${
                recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      createLargeOrder();
    }
  };

  // handle allowed years for selection field
  // if current month is December then show next year as a first option because new records are allowed only in the future
  const referrenceYear =
    new Date().getMonth() === 11
      ? new Date().getFullYear() + 1
      : new Date().getFullYear();
  // year values to display in the selection box and the next 20 years:
  const nextYearsOptions = [{ value: "", label: "Choose year..." }];
  for (let year = referrenceYear; year < referrenceYear + 20; year++) {
    nextYearsOptions.push({ value: year, label: year });
  }

  // handle allowed months for selection field
  // if current year do not show current month nor previous months
  const allowedMonthsOptions = function (selectedYear) {
    return monthsOptions.filter(
      (item) =>
        selectedYear === new Date().getFullYear()
          ? item.value >= new Date().getMonth() + 2 // is next month and later
          : item // in case of future years show any month
    );
  };

  async function copyPageUrl(id) {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}${window.location.pathname}?record=large-order&id=${id}` //change to .href if all records are opened based on URL
      );
      toast("Record URL copied to clipboard");
    } catch (err) {
      // if (process.env.NODE_ENV === "development") throw err
    }
  }

  function handleConfirm() {
    setShowConfirmBox((prev) => !prev);
  }

  // if the record is CRM Synced (syncId exists) should have read-only fields in the UI (disabled)
  const isSyncRecord = recordPanelData.sync;
  const isGidUser = ["gid"].includes(user.role[0]);

  // check if the record is a past record (should not be editable)
  let isFutureRecord =
    recordPanelData.year > new Date().getFullYear() ||
    (recordPanelData.year === new Date().getFullYear() &&
      recordPanelData.month > new Date().getMonth() + 1);

  const defaultTerritoryId = useMemo(() => {
    let territory = null;
    if (!recordPanelData && territoriesOptions && globalSelectedLSO && lsos) {
      const territoryId = lsos.find(
        (lso) => lso.id === Number(globalSelectedLSO)
      ).territories[0].id;
      territory = territoriesOptions.find(
        (territory) => territory.value === territoryId
      );
    }

    if (!territory) {
      return null;
    }

    return territory.value;
  }, [recordPanelData, territoriesOptions, globalSelectedLSO, lsos]);

  const initialValues = recordPanelData
    ? { ...recordPanelData }
    : {
        ...defaultFields,
        territoryId: defaultTerritoryId,
      };

  function calculateDiscountValue(value = 0, discount = 0) {
    if (discount > 100) return 0;
    const discountValue = value - (discount / 100) * value;
    return discountValue;
  }
  return (
    <motion.div
      className={styles.formPanel}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <div className={styles.fieldsContainer}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={validate}
          onSubmit={submit}
        >
          {(props) => {
            return (
              <Form>
                <div className={styles.panelHeader}>
                  <AnimatePresence exitBeforeEnter>
                    {showConfirmBox ? (
                      <motion.div
                        initial={{ y: -64 }}
                        animate={{ y: 0 }}
                        exit={{ y: -64 }}
                        className={styles.confirmBox}
                      >
                        <p
                          className={styles.panelLabel}
                          style={{ color: "darkred" }}
                        >
                          Delete record?
                        </p>

                        <Button
                          name="close"
                          // type="button"
                          color="default"
                          onClick={(e) => {
                            e.preventDefault();
                            handleConfirm();
                          }}
                        >
                          {/* <span className="material-icons">close</span> */}
                          Cancel
                        </Button>

                        <Button
                          name="delete"
                          type="button"
                          color="danger"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(e);
                          }}
                        >
                          Confirm
                        </Button>
                      </motion.div>
                    ) : (
                      <>
                        <p className={styles.panelLabel}>
                          {recordPanelData ? "Edit" : "New"} {label}
                        </p>

                        <div className={styles.buttonGroup}>
                          {!recordPanelData && (
                            <Button
                              className={styles.deleteBtn}
                              name="delete"
                              type="button"
                              color="default"
                              onClick={() => props.handleReset()}
                            >
                              Reset
                            </Button>
                          )}
                          {["admin", "rsm"].includes(user.role[0]) &&
                            recordPanelData &&
                            isFutureRecord && (
                              <Button
                                className={styles.deleteBtn}
                                name="delete"
                                type="button"
                                color={sm ? "default" : "danger"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleConfirm();
                                }}
                              >
                                {sm ? (
                                  <span className="material-icons">
                                    delete_outline
                                  </span>
                                ) : (
                                  "Delete"
                                )}
                              </Button>
                            )}

                          {/* update/create button should be hidden when user is gid */}
                          {!isGidUser ? (
                            <Button
                              name="add"
                              color={sm ? "default" : "primary"}
                              type="submit"
                              disabled={
                                recordPanelData && !props.dirty ? true : false
                              }
                            >
                              {recordPanelData ? "Update" : "Create"}
                            </Button>
                          ) : null}
                          <Button
                            name="close"
                            type="button"
                            color="default"
                            minWidth="auto"
                            onClick={closePanel}
                          >
                            <CloseIcon style={{ fill: "currentColor" }} />
                          </Button>
                        </div>
                      </>
                    )}
                  </AnimatePresence>
                </div>

                <div className={styles.inputList}>
                  {recordPanelData && (
                    <div className={styles.bottomButtons}>
                      <Button
                        className={styles.deleteBtn}
                        name="history"
                        type="button"
                        color={"default"}
                        onClick={(e) => {
                          e.preventDefault();
                          setRecordPanelData("");
                          defaultFieldsRef.current = {
                            test: "good",
                            ...recordPanelData,
                            year: "",
                            month: "",
                          };
                          setRecordPanelType(window.location.pathname);
                          setShowRecordPanel(true);
                        }}
                        display={"flex"}
                      >
                        <MoveIcon style={{ fill: "currentColor", width: 24 }} />
                        Move Order
                      </Button>
                      <Button
                        className={styles.deleteBtn}
                        name="history"
                        type="button"
                        color={"default"}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowHistoryTable(recordPanelData);
                        }}
                        display={"flex"}
                      >
                        <HistoryIcon style={{ fill: "currentColor" }} />
                        See History
                      </Button>
                      <Button
                        className={styles.deleteBtn}
                        name="copyLink"
                        type="button"
                        color={"default"}
                        onClick={(e) => {
                          e.preventDefault();
                          copyPageUrl(props.values.id);
                        }}
                        display={"flex"}
                      >
                        <LinkIcon
                          style={{
                            fill: "currentColor",
                            transform: " rotate(-45deg)",
                          }}
                        />
                        Copy Link
                      </Button>

                      {recordPanelData.sync && (
                        <p
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 4,
                            margin: 0,
                            padding: "2px 8px",
                            background: "#0080001a",
                            borderRadius: "10px",
                          }}
                        >
                          <span
                            role="img"
                            title={"Record from CRM"}
                            aria-label="yes"
                            style={{
                              fontSize: 23,
                              color: "green",
                              fontWeight: 700,
                            }}
                          >
                            ✔
                          </span>{" "}
                          CRM Synced
                        </p>
                      )}
                    </div>
                  )}
                  <Field as="select" id="year" name="year">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Year</span>
                          {!recordPanelData && !meta.touched && (
                            <span className={styles.errorText}>Required</span>
                          )}
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="year"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        {!isFutureRecord && recordPanelData ? (
                          <input
                            disabled={true}
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.inputError
                                  : styles.inputValid
                                : styles.input
                            }
                            type="number"
                            {...field}
                          />
                        ) : (
                          <Select
                            isDisabled={
                              recordPanelData &&
                              (isSyncRecord || !isFutureRecord || isGidUser)
                            }
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.selectBoxError
                                  : styles.selectBoxValid
                                : null
                            }
                            options={nextYearsOptions}
                            placeholder={"Choose year..."}
                            isClearable={false}
                            isSearchable={true}
                            isMulti={false}
                            value={
                              nextYearsOptions
                                ? nextYearsOptions.find(
                                    (option) =>
                                      option.value === props.values.year
                                  )
                                : null
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            onChange={(option) => {
                              form.setFieldValue(field.name, option.value);
                            }}
                          />
                        )}
                      </label>
                    )}
                  </Field>

                  {props.values.year && (
                    <Field as="select" id="month" name="month">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>Month</span>
                            {meta.touched && !meta.error && (
                              <span className={styles.check}>✔ Good</span>
                            )}
                            <ErrorMessage
                              name="month"
                              render={(msg) => (
                                <div className={styles.errorText}>{msg}</div>
                              )}
                            />
                          </div>
                          <Select
                            isDisabled={
                              recordPanelData &&
                              (isSyncRecord || !isFutureRecord || isGidUser)
                            }
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.selectBoxError
                                  : styles.selectBoxValid
                                : null
                            }
                            options={allowedMonthsOptions(props.values.year)}
                            placeholder={"Choose month..."}
                            isClearable={false}
                            isSearchable={true}
                            isMulti={false}
                            value={
                              monthsOptions
                                ? monthsOptions.find(
                                    (option) => option.value === field.value
                                  )
                                : ""
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            onChange={(option) => {
                              form.setFieldValue(field.name, option.value);
                            }}
                          />
                        </label>
                      )}
                    </Field>
                  )}

                  <Field as="input" id="orderValue" name="orderValue">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // { value, touched, error, initialValue }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>
                            Order Value (€){" "}
                            {!recordPanelData && (
                              <span style={{ color: "gray" }}>
                                {largeOrdersThreshold > 0
                                  ? `[Threshold = ${largeOrdersThreshold}]`
                                  : "[Threshold is not set]"}
                              </span>
                            )}
                          </span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="orderValue"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <input
                          disabled={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.inputError
                                : styles.inputValid
                              : styles.input
                          }
                          type="number"
                          min={
                            !recordPanelData && largeOrdersThreshold
                              ? largeOrdersThreshold
                              : 0
                          }
                          step={"any"}
                          placeholder={
                            largeOrdersThreshold
                              ? "Type order value equal or above the threshold..."
                              : "Type order value..."
                          }
                          {...field}
                        />
                      </label>
                    )}
                  </Field>

                  <Field as="input" id="discount" name="discount">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // { value, touched, error, initialValue }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Discount (%)</span>
                          {field.value > 0 && (
                            <span>
                              [Discounted Value:{" "}
                              {!meta.touched
                                ? field.value && form.values.discountedValue
                                : calculateDiscountValue(
                                    Number(form.values.orderValue),
                                    Number(field.value)
                                  )}
                              ]
                            </span>
                          )}
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="discount"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <input
                          disabled={
                            recordPanelData && (!isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.inputError
                                : styles.inputValid
                              : styles.input
                          }
                          type="number"
                          min={0}
                          max={100}
                          step={1}
                          placeholder={
                            recordPanelData && (!isFutureRecord || isGidUser)
                              ? "n/a"
                              : "Type discount value..."
                          }
                          {...field}
                        />
                      </label>
                    )}
                  </Field>

                  <Field as="input" id="opportunityName" name="opportunityName">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // { value, touched, error, initialValue }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Quotation Name</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="opportunityName"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <input
                          disabled={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.inputError
                                : styles.inputValid
                              : styles.input
                          }
                          type="text"
                          placeholder="Type quotation name..."
                          {...field}
                        />
                      </label>
                    )}
                  </Field>

                  <Field
                    as="input"
                    id="opportunityNumber"
                    name="opportunityNumber"
                  >
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // { value, touched, error, initialValue }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Quotation Number</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="opportunityNumber"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <input
                          disabled={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.inputError
                                : styles.inputValid
                              : styles.input
                          }
                          type="text"
                          placeholder={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                              ? "n/a"
                              : "Type quotation number..."
                          }
                          {...field}
                        />
                      </label>
                    )}
                  </Field>

                  <Field as="input" id="referenceA" name="referenceA">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // { value, touched, error, initialValue }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Reference A</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="referenceA"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <input
                          disabled={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.inputError
                                : styles.inputValid
                              : styles.input
                          }
                          type="text"
                          placeholder={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                              ? "n/a"
                              : "Type Reference A..."
                          }
                          {...field}
                        />
                      </label>
                    )}
                  </Field>

                  <Field as="input" id="accountName" name="accountName">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // { value, touched, error, initialValue }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>End Customer Name</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="accountName"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <input
                          disabled={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.inputError
                                : styles.inputValid
                              : styles.input
                          }
                          type="text"
                          placeholder="Type end customer name..."
                          {...field}
                        />
                      </label>
                    )}
                  </Field>

                  <Field as="select" id="countryId" name="countryId">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Project Country</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="countryId"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          isDisabled={
                            recordPanelData &&
                            ((isSyncRecord && field.value) ||
                              !isFutureRecord ||
                              isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : null
                          }
                          options={countriesOptions} //items from db
                          placeholder={"Choose country..."}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          value={
                            countriesOptions
                              ? countriesOptions.find(
                                  (option) => option.value === field.value
                                )
                              : ""
                          }
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                        />
                      </label>
                    )}
                  </Field>

                  <Field as="select" id="territoryId" name="territoryId">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Territory</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="territoryId"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          isDisabled={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : null
                          }
                          options={territoriesOptions} //items from db
                          placeholder={
                            recordPanelData && (!isFutureRecord || isGidUser)
                              ? "n/a"
                              : "Choose territory..."
                          }
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          value={
                            territoriesOptions
                              ? territoriesOptions.find(
                                  (option) => option.value === field.value
                                )
                              : ""
                          }
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                        />
                      </label>
                    )}
                  </Field>

                  <Field
                    as="select"
                    id="globalIndustryId"
                    name="globalIndustryId"
                  >
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Global Industry (GID)</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="globalIndustryId"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          isDisabled={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : null
                          }
                          options={gidOptions} //items from db
                          placeholder={"Choose global industry.."}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          value={
                            gidOptions
                              ? gidOptions.find(
                                  (option) => option.value === field.value
                                )
                              : ""
                          }
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                        />
                      </label>
                    )}
                  </Field>

                  <Field
                    as="select"
                    id="winProbabilityId"
                    name="winProbabilityId"
                  >
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Win Probability</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="winProbabilityId"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          isDisabled={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : null
                          }
                          options={winProbabilityOptions} //items from db
                          placeholder={"Choose win probability.."}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          value={
                            winProbabilityOptions
                              ? winProbabilityOptions.find(
                                  (option) => option.value === field.value
                                )
                              : ""
                          }
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                        />
                      </label>
                    )}
                  </Field>

                  <Field as="select" id="mustWin" name="mustWin">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Must Win?</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="mustWin"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          isDisabled={
                            recordPanelData &&
                            (isSyncRecord || !isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : null
                          }
                          options={booleanOptions} //items from db
                          placeholder={"Choose must win..."}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          value={
                            booleanOptions
                              ? booleanOptions.find(
                                  (option) => option.value === field.value
                                )
                              : ""
                          }
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                        />
                      </label>
                    )}
                  </Field>

                  <Field as="select" id="classification" name="classification">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Alternative Order Status</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="classification"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          isDisabled={
                            recordPanelData && (!isFutureRecord || isGidUser)
                          }
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : null
                          }
                          options={classificationOptions} //items from db
                          placeholder={
                            recordPanelData && (!isFutureRecord || isGidUser)
                              ? "n/a "
                              : "Choose alternative order status.."
                          }
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          value={
                            classificationOptions
                              ? classificationOptions.find(
                                  (option) => option.value === field.value
                                )
                              : ""
                          }
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                        />
                      </label>
                    )}
                  </Field>

                  <Field as="textarea" id="remarks" name="remarks">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // { value, touched, error, initialValue }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Remarks</span>
                        </div>
                        <textarea
                          disabled={
                            recordPanelData && (!isFutureRecord || isGidUser)
                          }
                          className={styles.input}
                          placeholder={
                            recordPanelData && (!isFutureRecord || isGidUser)
                              ? "n/a"
                              : "Type remarks..."
                          }
                          {...field}
                        />
                      </label>
                    )}
                  </Field>

                  {recordPanelData && (
                    <>
                      <Field
                        as="input"
                        id="nonErpQuoteNumber"
                        name="nonErpQuoteNumber"
                      >
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta, // { value, touched, error, initialValue }
                        }) => (
                          <label className={styles.label}>
                            <div className={styles.fieldHeader}>
                              <span>Non ERP Quote Number</span>
                              {meta.touched && !meta.error && (
                                <span className={styles.check}>✔ Good</span>
                              )}
                              <ErrorMessage
                                name="nonErpQuoteNumber"
                                render={(msg) => (
                                  <div className={styles.errorText}>{msg}</div>
                                )}
                              />
                            </div>
                            <input
                              disabled={true}
                              className={
                                meta.touched
                                  ? meta.error
                                    ? styles.inputError
                                    : styles.inputValid
                                  : styles.input
                              }
                              type="number"
                              placeholder={
                                isSyncRecord
                                  ? "n/a"
                                  : "Type Non ERP Quote Number..."
                              }
                              {...field}
                            />
                          </label>
                        )}
                      </Field>
                      <Field as="select" id="phase" name="phase">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta, // {error, initialError, initalTouched, initialValue, touched, value }
                        }) => (
                          <label className={styles.label}>
                            <div className={styles.fieldHeader}>
                              <span>Phase</span>
                              {meta.touched && !meta.error && (
                                <span className={styles.check}>✔ Good</span>
                              )}
                              <ErrorMessage
                                name="phase"
                                render={(msg) => (
                                  <div className={styles.errorText}>{msg}</div>
                                )}
                              />
                            </div>
                            <Select
                              isDisabled={true}
                              className={
                                meta.touched
                                  ? meta.error
                                    ? styles.selectBoxError
                                    : styles.selectBoxValid
                                  : null
                              }
                              options={phaseOptions}
                              placeholder={"n/a"}
                              isClearable={false}
                              isSearchable={true}
                              isMulti={false}
                              value={
                                phaseOptions
                                  ? phaseOptions.find(
                                      (option) => option.value === field.value
                                    )
                                  : ""
                              }
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              onChange={(option) => {
                                form.setFieldValue(field.name, option.value);
                              }}
                            />
                          </label>
                        )}
                      </Field>
                      <Field as="input" id="owner" name="owner">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta, // { value, touched, error, initialValue }
                        }) => (
                          <label className={styles.label}>
                            <div className={styles.fieldHeader}>
                              <span>Owner</span>
                              {meta.touched && !meta.error && (
                                <span className={styles.check}>✔ Good</span>
                              )}
                              <ErrorMessage
                                name="owner"
                                render={(msg) => (
                                  <div className={styles.errorText}>{msg}</div>
                                )}
                              />
                            </div>
                            <input
                              disabled={true}
                              className={
                                meta.touched
                                  ? meta.error
                                    ? styles.inputError
                                    : styles.inputValid
                                  : styles.input
                              }
                              type="text"
                              placeholder={
                                isSyncRecord ? "n/a" : "Type owner..."
                              }
                              {...field}
                            />
                          </label>
                        )}
                      </Field>
                      <label
                        className={styles.label}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "20px",
                        }}
                      >
                        Last modified on
                        <input
                          disabled={true}
                          className={styles.input}
                          id={"lastModifiedDate"}
                          name={"lastModifiedDate"}
                          type={"text"}
                          value={new Date(
                            props.values.lastModifiedDate
                          )?.toLocaleDateString(browserLang)}
                        />
                      </label>
                      <label
                        className={styles.label}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "20px",
                        }}
                      >
                        Last modified by
                        <input
                          disabled={true}
                          className={styles.input}
                          id={
                            ["admin", "rsm"].includes(user.role[0])
                              ? "lastModifiedBy"
                              : "lastModifiedByUser"
                          }
                          name={
                            ["admin", "rsm"].includes(user.role[0])
                              ? "lastModifiedBy"
                              : "lastModifiedByUser"
                          }
                          type={"text"}
                          value={props.values.lastModifiedByUser}
                        />
                      </label>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </motion.div>
  );
}

export default RecordPanelLargeOrders;
