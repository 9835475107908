import { useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { Button, Input } from "../style/ComponentsLibrary";
import styles from "./Auth.module.css";
import logo from "../assets/KROHNE_logo.svg";
import { motion } from "framer-motion";
import DB_URL from "../environments";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import packageJSON from "../../package.json";

const Auth = () => {
  const appVersion = packageJSON.version;
  const { setUser, loginMessage, setLoginMessage } = useContext(UserContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    async function getUser() {
      try {
        let response = await fetch(DB_URL + "/api/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({
            username: username,
            password: password,
          }),
        });

        const result = await response.json();

        if (response.ok) {
          setUser(result);
        } else {
          if (result.code === 403) {
            setLoginMessage(result.message);
          } else {
            toast.error(result.message || "Cannot continue. Retry later.");
          }
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
        setLoginMessage("Failed to fetch. Retry later.");
      }
    }

    getUser();
  };

  return (
    <div className={styles.loginScreen}>
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <motion.div
        className={styles.formContainer}
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.75 }}
      >
        <img src={logo} className={styles.logo} alt="logo" />
        <motion.h1
          initial={{ opacity: 0, scale: 2, y: 50 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={{ duration: 1 }}
          style={{
            color: "#808B94",
            margin: "40px 0",
            letterSpacing: "-0.75px",
          }}
        >
          mySales
        </motion.h1>
        <form className={styles.form} onSubmit={handleSubmit}>
          <label className={styles.label}>
            <p className={styles.labelText}>Username</p>
            <Input
              autoFocus={true}
              dataCy="username"
              name="username"
              type="search"
              required={true}
              placeholder="Type your username..."
              onChange={(e) => setUsername(e.target.value)}
            ></Input>
          </label>
          <label className={styles.label}>
            <p className={styles.labelText}>Password</p>
            <Input
              dataCy="password"
              name="password"
              type="password"
              required={true}
              placeholder="Type your password..."
              onChange={(e) => setPassword(e.target.value)}
            ></Input>
          </label>

          {loginMessage && (
            <p
              style={{
                color: "darkred",
                padding: 8,
                backgroundColor: "#ff000010",
                borderRadius: 6,
              }}
              className="error-text"
            >
              {loginMessage}
            </p>
          )}

          <motion.div
            initial={{ y: 50, opacity: 0, scale: 1.25 }}
            animate={{ y: 0, opacity: 1, scale: 1.1 }}
            transition={{ duration: 0.75, delay: 0.6 }}
            // whileHover={{ scale: 1.15 }}
            // whileTap={{ scale: 0.9 }}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              dataCy="submit"
              margin="8px"
              color="primary"
              name="submit"
              type="submit"
              onClick={handleSubmit}
            >
              SIGN IN
            </Button>
          </motion.div>
        </form>
      </motion.div>
      <p style={{ color: "gray", fontSize: "0.85rem" }}>version: {appVersion}</p>
    </div>
  );
};

export default Auth;
