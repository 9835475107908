import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/history.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { useState, useContext, useEffect } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import styles from "./RecordPanel.module.css";
import { Button } from "../../style/ComponentsLibrary";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCheckMediaQuery from "../../hooks/useCheckMediaQuery";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { UserContext } from "../../context/UserContext";
import { motion, AnimatePresence } from "framer-motion";
import DB_URL from "../../environments";
import { monthsOptions } from "../../utils/fields_options";
import { sessionExpirationChecker } from "../../utils/fetch_helpers";
import { useCloseRecordPanel } from ".";

const browserLang = navigator.language;

function RecordPanelOrderIncome() {
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const { user, logoutUser } = useContext(UserContext);
  const label = "Order Income";
  const sm = useCheckMediaQuery("(max-width: 440px)");
  const {
    setShowRecordPanel,
    recordPanelData,
    setRecordPanelData,
    setShowHistoryTable,
    setShouldTableRefresh,
    setSuccessMessage,
  } = useContext(LayoutContext);
  const [lsoOptions, setLsoOptions] = useState();
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [largeSalesOrganizationId, setLargeSalesOrganizationId] = useState();
  const { closePanel } = useCloseRecordPanel();

  useEffect(() => {
    async function getLSOs() {
      try {
        let response = await fetch(
          DB_URL + "/api/admin/large-sales-organization/search",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({
              ignoreCase: true,
              key: "",
              pageNumber: 1,
              pageSize: 1,
              sortColumn: "",
              sortDirection: "ASC",
              unpaged: true,
            }),
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();

        if (result) {
          const formik_lso_options = result.content.map((lso) => ({
            value: lso.id,
            label: lso.name,
          }));
          setLsoOptions(formik_lso_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    getLSOs();
  }, [user, logoutUser]);

  const handleDelete = (e) => {
    // delete from database:
    e.preventDefault();
    async function deleteOrderIncome() {
      try {
        let response = await fetch(
          DB_URL + `/api/order-income/${recordPanelData["id"]}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response;

        if (response.ok) {
          setShouldTableRefresh(new Date().getTime());
          setRecordPanelData("");
          setShowRecordPanel(false);
          setSuccessMessage(
            `Record deleted at ${new Date().toLocaleTimeString()}`
          );
        } else {
          toast.error(result.message);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    deleteOrderIncome();
  };

  const defaultValues = {
    largeSalesOrganizationId: "",
    year: "",
    month: "",
    actualOrderIncome: "",
    lastModifiedDate: "",
    lastModifiedByUser: "",
    largeOrderForecast: "",
  };

  const validate = (values) => {
    let errors = {};

    if (!values.largeSalesOrganizationId)
      errors.largeSalesOrganizationId = "Required";
    if (!values.year) errors.year = "Required";
    if (!values.month) errors.month = "Required";

    return errors;
  };

  const submit = (values) => {
    // refactoring the values:
    const putValues = {
      actualOrderIncome: values.actualOrderIncome,
      bookingsBaseload: values.bookingsBaseload,
      bookingsBaseloadAdjustments: values.bookingsBaseloadAdjustments,
      largeSalesOrganizationId: values.largeSalesOrganizationId,
      month: values.month,
      quotationsBaseload: values.quotationsBaseload,
      quotationsBaseloadAdjustments: values.quotationsBaseloadAdjustments,
      year: values.year,
    };

    // if edit row button is clicked (row data in saved in "recordPanelData"):
    if (recordPanelData) {
      setShowRecordPanel(false);
      //delete some keys & values before updating:
      // UPDATE record to db
      // update record to database:
      async function updateOrderIncome() {
        try {
          let response = await fetch(
            DB_URL + `/api/order-income/${recordPanelData["id"]}/`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
              body: JSON.stringify(putValues),
            }
          );
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${
                recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          toast.error("An error occured. A page refresh may help.");
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      updateOrderIncome();
    } else {
      // Create Record to db
      // add record to database:
      async function createOrderIncome() {
        try {
          let response = await fetch(DB_URL + "/api/order-income/", {
            method: "PUT",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify(putValues),
          });
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${
                recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      createOrderIncome();
    }
  };

  // handle allowed years for selection field
  // if current month is December then show next year as a first option because new records are allowed only in the future
  const referrenceYear =
    new Date().getMonth() === 11
      ? new Date().getFullYear() + 1
      : new Date().getFullYear();
  // should display current year and the next 20 years:
  const yearsOptions = [];
  for (let year = referrenceYear; year <= referrenceYear + 20; year++) {
    yearsOptions.push({ value: year, label: year });
  }

  // handle allowed months for selection field
  // if current year do not show current month nor previous months
  const allowedMonthsOptions = function (selectedYear) {
    return monthsOptions.filter(
      (item) =>
        selectedYear === new Date().getFullYear()
          ? item.value >= new Date().getMonth() + 2 // is next month and later
          : item // in case of future years show any month
    );
  };

  async function copyPageUrl(id) {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}${window.location.pathname}?record=order-income&id=${id}` //change to .href if all records are opened based on URL
      );
      toast("Record URL copied to clipboard");
    } catch (err) {
      // if (process.env.NODE_ENV === "development") throw err
    }
  }

  const initialValues = recordPanelData
    ? {
        ...recordPanelData,
        // ["largeOrderForecast"]: recordPanelData["largeOrderForecast"].toFixed(2),
      }
    : defaultValues;

  function handleConfirm() {
    setShowConfirmBox((prev) => !prev);
  }

  return (
    <motion.div
      className={styles.formPanel}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <div className={styles.fieldsContainer}>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={submit}
        >
          {(props) => {
            // calculate future months:
            let isFutureMonth;
            if (props.values.year === new Date().getFullYear()) {
              if (props.values.month > new Date().getMonth() + 1) {
                isFutureMonth = true;
              } else {
                isFutureMonth = false;
              }
            } else if (props.values.year >= new Date().getFullYear()) {
              isFutureMonth = true;
            } else if (props.values.year <= new Date().getFullYear()) {
              isFutureMonth = false;
            }
            return (
              <Form>
                <div className={styles.panelHeader}>
                  <AnimatePresence exitBeforeEnter>
                    {showConfirmBox ? (
                      <motion.div
                        initial={{ y: -64 }}
                        animate={{ y: 0 }}
                        exit={{ y: -64 }}
                        className={styles.confirmBox}
                      >
                        <p
                          className={styles.panelLabel}
                          style={{ color: "darkred" }}
                        >
                          Delete record?
                        </p>

                        <Button
                          name="close"
                          // type="button"
                          color="default"
                          onClick={(e) => {
                            e.preventDefault();
                            handleConfirm();
                          }}
                        >
                          {/* <span className="material-icons">close</span> */}
                          Cancel
                        </Button>

                        <Button
                          name="delete"
                          type="button"
                          color="danger"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(e);
                          }}
                        >
                          Confirm
                        </Button>
                      </motion.div>
                    ) : (
                      <>
                        <p className={styles.panelLabel}>
                          {recordPanelData ? "Edit" : "New"} {label}
                        </p>

                        <div className={styles.buttonGroup}>
                          {!recordPanelData && (
                            <Button
                              className={styles.deleteBtn}
                              name="delete"
                              type="button"
                              color="default"
                              onClick={() => props.handleReset()}
                            >
                              Reset
                            </Button>
                          )}

                          {["admin", "rsm"].includes(user.role[0]) &&
                            recordPanelData && (
                              <Button
                                className={styles.deleteBtn}
                                name="delete"
                                type="button"
                                color={sm ? "default" : "danger"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleConfirm();
                                }}
                              >
                                {sm ? (
                                  <span className="material-icons">
                                    delete_outline
                                  </span>
                                ) : (
                                  "Delete"
                                )}
                              </Button>
                            )}

                          <Button
                            name="add"
                            color={sm ? "default" : "primary"}
                            type="submit"
                            disabled={
                              recordPanelData && !props.dirty ? true : false
                            }
                          >
                            {recordPanelData ? "Update" : "Create"}
                          </Button>

                          <Button
                            name="close"
                            type="button"
                            color="default"
                            minWidth="auto"
                            onClick={closePanel}
                          >
                            <CloseIcon style={{ fill: "currentColor" }} />
                          </Button>
                        </div>
                      </>
                    )}
                  </AnimatePresence>
                </div>

                <div className={styles.inputList}>
                  {recordPanelData && (
                    <div className={styles.bottomButtons}>
                      <Button
                        className={styles.deleteBtn}
                        name="history"
                        type="button"
                        color={"default"}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowHistoryTable(recordPanelData);
                        }}
                        display={"flex"}
                      >
                        <HistoryIcon style={{ fill: "currentColor" }} />
                        See History
                      </Button>
                      <Button
                        className={styles.deleteBtn}
                        name="copyLink"
                        type="button"
                        color={"default"}
                        onClick={(e) => {
                          e.preventDefault();
                          copyPageUrl(props.values.id);
                        }}
                        display={"flex"}
                      >
                        <LinkIcon
                          style={{
                            fill: "currentColor",
                            transform: " rotate(-45deg)",
                          }}
                        />
                        Copy Link
                      </Button>
                    </div>
                  )}
                  <Field
                    as="select"
                    id="largeSalesOrganizationId"
                    name="largeSalesOrganizationId"
                  >
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Accounting Entity</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="largeSalesOrganizationId"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          isDisabled={recordPanelData ? true : false}
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : null
                          }
                          options={lsoOptions} //items from db
                          placeholder={"Choose Accounting Entity..."}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          value={
                            lsoOptions
                              ? lsoOptions.find(
                                  (option) => option.value === field.value
                                )
                              : ""
                          }
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                            setLargeSalesOrganizationId(option.value);
                          }}
                        />
                      </label>
                    )}
                  </Field>
                  <Field as="select" id="year" name="year">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Year</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="year"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          isDisabled={recordPanelData ? true : false}
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : null
                          }
                          options={yearsOptions}
                          placeholder={"Choose year..."}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          value={
                            yearsOptions
                              ? yearsOptions.find(
                                  (option) => option.value === field.value
                                )
                              : ""
                          }
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                            setYear(option.value);
                          }}
                        />
                      </label>
                    )}
                  </Field>

                  {props.values.year && (
                    <Field as="select" id="month" name="month">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>Month</span>
                            {meta.touched && !meta.error && (
                              <span className={styles.check}>✔ Good</span>
                            )}
                            <ErrorMessage
                              name="month"
                              render={(msg) => (
                                <div className={styles.errorText}>{msg}</div>
                              )}
                            />
                          </div>
                          <Select
                            isDisabled={recordPanelData ? true : false}
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.selectBoxError
                                  : styles.selectBoxValid
                                : null
                            }
                            options={allowedMonthsOptions(props.values.year)}
                            placeholder={"Choose month..."}
                            isClearable={false}
                            isSearchable={true}
                            isMulti={false}
                            value={
                              monthsOptions
                                ? monthsOptions.find(
                                    (option) => option.value === field.value
                                  )
                                : ""
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            onChange={(option) => {
                              form.setFieldValue(field.name, option.value);
                              setMonth(option.value);
                            }}
                          />
                        </label>
                      )}
                    </Field>
                  )}

                  {recordPanelData && (
                    <Field as="input" id="plannedBudget" name="plannedBudget">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // { value, touched, error, initialValue }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>Planned Budget (€)</span>
                          </div>
                          <input
                            disabled={true}
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.inputError
                                  : styles.inputValid
                                : styles.input
                            }
                            type={field.value ? "number" : "text"}
                            placeholder={
                              field.value ||
                              "Calculated based on selected Accounting Entity, year and month"
                            }
                            value={field.value}
                            // {...field}
                          />
                        </label>
                      )}
                    </Field>
                  )}
                  {recordPanelData && (
                    <Field
                      as="input"
                      id="actualOrderIncome"
                      name="actualOrderIncome"
                    >
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // { value, touched, error, initialValue }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>Actual Order Income (€)</span>
                            {meta.touched && !meta.error && (
                              <span className={styles.check}>✔ Good</span>
                            )}
                            <ErrorMessage
                              name="actualOrderIncome"
                              render={(msg) => (
                                <div className={styles.errorText}>{msg}</div>
                              )}
                            />
                          </div>
                          <input
                            disabled={isFutureMonth}
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.inputError
                                  : styles.inputValid
                                : styles.input
                            }
                            type="number"
                            placeholder={isFutureMonth ? "n/a" : "Type actual order income..."}
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                  )}
                  {recordPanelData && (
                    <Field as="input" id="currentTotal" name="currentTotal">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // { value, touched, error, initialValue }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>Total forecast (€)</span>
                          </div>
                          <input
                            disabled={true}
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.inputError
                                  : styles.inputValid
                                : styles.input
                            }
                            type={field.value ? "number" : "text"}
                            placeholder={
                              field.value ||
                              "Calculated based on selected Accounting Entity, year and month"
                            }
                            value={field.value}
                            // {...field}
                          />
                        </label>
                      )}
                    </Field>
                  )}
                  {!recordPanelData &&
                  (!year || !month || !largeSalesOrganizationId) ? null : (
                    <Field
                      as="input"
                      id="quotationsBaseload"
                      name="quotationsBaseload"
                    >
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // { value, touched, error, initialValue }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>Quotations (€)</span>
                            {meta.touched && !meta.error && (
                              <span className={styles.check}>✔ Good</span>
                            )}
                            <ErrorMessage
                              name="quotationsBaseload"
                              render={(msg) => (
                                <div className={styles.errorText}>{msg}</div>
                              )}
                            />
                          </div>
                          <input
                            //disable this input field if is from the past:
                            disabled={
                              !isFutureMonth && ["basic"].includes(user.role[0])
                                ? true
                                : false
                            }
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.inputError
                                  : styles.inputValid
                                : styles.input
                            }
                            type="number"
                            placeholder={
                              isFutureMonth ? "Type quotations..." : "0"
                            }
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                  )}
                  {!recordPanelData &&
                  (!year || !month || !largeSalesOrganizationId) ? null : (
                    <Field
                      as="input"
                      id="quotationsBaseloadAdjustments"
                      name="quotationsBaseloadAdjustments"
                    >
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // { value, touched, error, initialValue }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>Quotations Adjustments (€)</span>
                            {meta.touched && !meta.error && (
                              <span className={styles.check}>✔ Good</span>
                            )}
                            <ErrorMessage
                              name="quotationsBaseloadAdjustments"
                              render={(msg) => (
                                <div className={styles.errorText}>{msg}</div>
                              )}
                            />
                          </div>
                          <input
                            //disable this input field if is from the past:
                            disabled={
                              !isFutureMonth && ["basic"].includes(user.role[0])
                                ? true
                                : false
                            }
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.inputError
                                  : styles.inputValid
                                : styles.input
                            }
                            type="number"
                            placeholder={
                              isFutureMonth
                                ? "Type quotations adjustments..."
                                : "0"
                            }
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                  )}
                  {!recordPanelData &&
                  (!year || !month || !largeSalesOrganizationId) ? null : (
                    <Field
                      as="input"
                      id="bookingsBaseload"
                      name="bookingsBaseload"
                    >
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // { value, touched, error, initialValue }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>Bookings Baseload (€)</span>
                            {meta.touched && !meta.error && (
                              <span className={styles.check}>✔ Good</span>
                            )}
                            <ErrorMessage
                              name="bookingsBaseload"
                              render={(msg) => (
                                <div className={styles.errorText}>{msg}</div>
                              )}
                            />
                          </div>
                          <input
                            //disable this input field if is from the past:
                            disabled={
                              !isFutureMonth && ["basic"].includes(user.role[0])
                                ? true
                                : false
                            }
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.inputError
                                  : styles.inputValid
                                : styles.input
                            }
                            type="number"
                            placeholder={
                              isFutureMonth
                                ? "Type bookings adjustments..."
                                : "0"
                            }
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                  )}

                  {!recordPanelData &&
                  (!year || !month || !largeSalesOrganizationId) ? null : (
                    <Field
                      as="input"
                      id="bookingsBaseloadAdjustments"
                      name="bookingsBaseloadAdjustments"
                    >
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // { value, touched, error, initialValue }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>Bookings Baseload Adjustments (€)</span>
                            {meta.touched && !meta.error && (
                              <span className={styles.check}>✔ Good</span>
                            )}
                            <ErrorMessage
                              name="bookingsBaseloadAdjustments"
                              render={(msg) => (
                                <div className={styles.errorText}>{msg}</div>
                              )}
                            />
                          </div>
                          <input
                            //disable this input field if is from the past:
                            disabled={
                              !isFutureMonth && ["basic"].includes(user.role[0])
                                ? true
                                : false
                            }
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.inputError
                                  : styles.inputValid
                                : styles.input
                            }
                            type="number"
                            placeholder={
                              isFutureMonth
                                ? "Type bookings adjustments..."
                                : "0"
                            }
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                  )}

                  {recordPanelData && (
                    <Field
                      as="input"
                      id="largeOrderForecast"
                      name="largeOrderForecast"
                    >
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // { value, touched, error, initialValue }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>Large Order Forecast (€)</span>
                          </div>
                          <input
                            disabled={true}
                            className={
                              meta.touched
                                ? meta.error
                                  ? styles.inputError
                                  : styles.inputValid
                                : styles.input
                            }
                            type={field.value ? "number" : "text"}
                            placeholder={
                              field.value ||
                              "Calculated based on selected Accounting Entity, year and month"
                            }
                            value={field.value || 0}
                            // {...field}
                          />
                        </label>
                      )}
                    </Field>
                  )}

                  {recordPanelData && (
                    <>
                      <label
                        className={styles.label}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "20px",
                        }}
                      >
                        Last modified on
                        <input
                          disabled={true}
                          className={styles.input}
                          id={"lastModifiedDate"}
                          name={"lastModifiedDate"}
                          type={"text"}
                          value={new Date(
                            props.values.lastModifiedDate
                          )?.toLocaleDateString(browserLang)}
                        />
                      </label>
                      <label
                        className={styles.label}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "20px",
                        }}
                      >
                        Last modified by
                        <input
                          disabled={true}
                          className={styles.input}
                          id={
                            ["admin", "rsm"].includes(user.role[0])
                              ? "lastModifiedBy"
                              : "lastModifiedByUser"
                          }
                          name={
                            ["admin", "rsm"].includes(user.role[0])
                              ? "lastModifiedBy"
                              : "lastModifiedByUser"
                          }
                          type={"text"}
                          value={props.values.lastModifiedByUser}
                        />
                      </label>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </motion.div>
  );
}

export default RecordPanelOrderIncome;
