import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { LayoutContext } from "../context/LayoutContext";
import DB_URL from "../environments";
import { sessionExpirationChecker } from "../utils/fetch_helpers";
import { useLocation } from "react-router-dom";

export default function useFetchRecord() {
  const { user, logoutUser } = useContext(UserContext);
  const { setRecordPanelData, setShowRecordPanel, setRecordPanelType } =
    useContext(LayoutContext);
  const location = useLocation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const recordType = searchParams.get("record");
  const recordId = searchParams.get("id");

  useEffect(() => {
    if (recordType === "order-income") {
      // fetch based on recordId
      async function getOrderIncome() {
        try {
          const response = await fetch(
            DB_URL + `/api/order-income/${recordId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
            }
          );
          sessionExpirationChecker(response, logoutUser);
          const result = await response.json();
          if (response.ok) {
            setRecordPanelData(result);
            setShowRecordPanel(true);
            setRecordPanelType(`/${recordType}`);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      getOrderIncome();
    }

    if (recordType === "large-order") {
      // fetch based on recordId
      async function getLargeOrder() {
        try {
          const response = await fetch(
            DB_URL + `/api/large-order/${recordId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
            }
          );

          sessionExpirationChecker(response, logoutUser);
          const result = await response.json();

          if (response.ok) {
            setRecordPanelData(result);
            setShowRecordPanel(true);
            setRecordPanelType(`/${recordType}s`); //large-orders
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      getLargeOrder();
    }

    if (recordType === "annual-budget") {
      // fetch based on recordId
      async function getAnnualBudget() {
        try {
          const response = await fetch(
            DB_URL + `/api/admin/annual-budget/${recordId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
            }
          );
          sessionExpirationChecker(response, logoutUser);
          const result = await response.json();
          if (response.ok) {
            setRecordPanelData(result);
            setShowRecordPanel(true);
            setRecordPanelType(`/${recordType}`);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      getAnnualBudget();
    }

    if (recordType === "lso") {
      // fetch based on recordId
      async function getLargeSalesOrganization() {
        try {
          const response = await fetch(
            DB_URL + `/api/admin/large-sales-organization/${recordId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
            }
          );
          sessionExpirationChecker(response, logoutUser);
          const result = await response.json();
          if (response.ok) {
            setRecordPanelData(result);
            setShowRecordPanel(true);
            setRecordPanelType(`/${recordType}`);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      getLargeSalesOrganization();
    }

    if (recordType === "user") {
      // fetch based on recordId
      async function getUser() {
        try {
          const response = await fetch(DB_URL + `/api/admin/user/${recordId}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
          });
          sessionExpirationChecker(response, logoutUser);
          const result = await response.json();

          if (response.ok) {
            setRecordPanelData(result);
            setShowRecordPanel(true);
            setRecordPanelType(`/${recordType}s`); //users
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      getUser();
    }
    if (recordType === "gid") {
      // fetch based on recordId
      async function getUser() {
        try {
          const response = await fetch(
            DB_URL + `/api/admin/global-industry/${recordId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
            }
          );
          sessionExpirationChecker(response, logoutUser);
          const result = await response.json();
          if (response.ok) {
            setRecordPanelData(result);
            setShowRecordPanel(true);
            setRecordPanelType(`/${recordType}`); //users
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      getUser();
    }
    if (recordType === "sales-region") {
      // fetch based on recordId
      async function getUser() {
        try {
          const response = await fetch(
            DB_URL + `/api/admin/sales-region/${recordId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
            }
          );
          sessionExpirationChecker(response, logoutUser);
          const result = await response.json();

          if (response.ok) {
            setRecordPanelData(result);
            setShowRecordPanel(true);
            setRecordPanelType(`/${recordType}`); //users
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      getUser();
    }
    if (recordType === "territory") {
      // fetch based on recordId
      async function getUser() {
        try {
          const response = await fetch(
            DB_URL + `/api/admin/territory/${recordId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
            }
          );
          sessionExpirationChecker(response, logoutUser);
          const result = await response.json();
          if (response.ok) {
            setRecordPanelData(result);
            setShowRecordPanel(true);
            setRecordPanelType(`/${recordType}`); //users
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      getUser();
    }
  }, [
    location,
    recordType,
    recordId,
    setRecordPanelType,
    setRecordPanelData,
    setShowRecordPanel,
    user,
    logoutUser,
  ]);
}
