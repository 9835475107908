import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function usePage() {
  const location = useLocation();
  // TODO turn variables below with React State: [searchFieldLabel, setSearchFieldLabel] !!!
  let searchFieldLabel; // placeholder inside the global search field in the header
  let pageTitle; // the name of the current page
  let table; // ???
  let label = ""; // used for title of the add new / edit record panel

  let dbCollection = ""; // database table or collection

  switch (location.pathname) {
    case "/dashboard":
      searchFieldLabel = "dashboard";
      pageTitle = "Dashboard";
      table = "";
      label = "";
      dbCollection = "";
      break;
    case "/order-income":
      searchFieldLabel = "order income";
      pageTitle = "Order Income";
      table = "orderIncome";
      label = "Order Income";
      dbCollection = "orders_income";
      break;
    case "/large-orders":
      searchFieldLabel = "large order";
      pageTitle = "Large Order Potential";
      table = "largeOrders";
      label = "Large Order Potential";
      dbCollection = "large_orders";
      break;
    case "/annual-budget":
      searchFieldLabel = "annual buget";
      pageTitle = "Annual Budget";
      table = "annual budget";
      label = "Annual Budget";

      dbCollection = "annual_budgets";
      break;
    case "/gid":
      searchFieldLabel = "GIDs";
      pageTitle = "GIDs";
      table = "gid";
      label = "GID";
      dbCollection = "GIDs";
      break;
    case "/lso":
      searchFieldLabel = "LSOs";
      pageTitle = "Accounting Entities";
      table = "lso";
      label = "Accounting Entity";
      dbCollection = "LSOs";
      break;
    case "/users":
      searchFieldLabel = "users";
      pageTitle = "Users";
      table = "users";
      label = "User";
      dbCollection = "users";
      break;
    case "/sales-regions":
      searchFieldLabel = "sales regions";
      pageTitle = "Sales Regions";
      table = "";
      label = "Sales Regions";
      dbCollection = "sales_regions";
      break;
    case "/sub-regions":
      searchFieldLabel = "sub-regions";
      pageTitle = "Sub-Regions";
      table = "";
      label = "Sub-Regions";
      dbCollection = "sub_regions";
      break;
    case "/win-probability":
      searchFieldLabel = "win probability";
      pageTitle = "Win Probabilities";
      table = "Win Probabilities";
      dbCollection = "win_probabilities";
      break;
    case "/countries":
      searchFieldLabel = "countries";
      pageTitle = "Countries";
      table = "countries";
      label = "Countries";
      dbCollection = "countries";
      break;
    case "/territory":
      searchFieldLabel = "territories";
      pageTitle = "Territories";
      table = "territories";
      label = "Territories";
      dbCollection = "territories";
      break;
    case "/sync":
      searchFieldLabel = "sync";
      pageTitle = "Sync History";
      table = "Sync Results";
      label = "Sync";
      break;
    case "/":
      searchFieldLabel = "";
      pageTitle = "";
      table = "";
      label = "";
      break;
    default:
      searchFieldLabel = "";
      pageTitle = "";
      table = "";
      label = "";
      dbCollection = "";
      break;
  }

  useEffect(() => {
    const local = window.location.hostname === "localhost" ? "localhost:" : "";
    if (pageTitle) {
      window.document.title = `${pageTitle} | ${local} mySales`;
    } else {
      window.document.title = `${local}mySales`;
    }
  }, [pageTitle]);

  return {
    searchFieldLabel,
    pageTitle,
    table,
    label,
    dbCollection,
  };
}
