// desired timeout shoud be max 3 minutes
const DESIRED_TIMEOUT = 3 * 60 * 1000;

export default async function fetchWithTimeout(
  resource,
  options = {},
  timeout = DESIRED_TIMEOUT
) {
  const controller = new AbortController();

  const id = setTimeout(() => {
    controller.abort();
  }, timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}
