import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { useEffect, useState, useContext } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { Button } from "../../style/ComponentsLibrary";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCheckMediaQuery from "../../hooks/useCheckMediaQuery";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import DB_URL from "../../environments";
import styles from "./RecordPanel.module.css";
import { sessionExpirationChecker } from "../../utils/fetch_helpers";
import { useCloseRecordPanel } from ".";

function RecordPanelLSO() {
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [countries, setCountries] = useState();
  const [salesRegions, setSalesRegions] = useState();
  const [subRegions, setSubRegions] = useState();
  const [territories, setTerritories] = useState();
  const [usedTerritories, setUsedTerritories] = useState([]);
  const { user, logoutUser } = useContext(UserContext);
  const label = "Accounting Entity";
  const sm = useCheckMediaQuery("(max-width: 440px)");
  const {
    setShowRecordPanel,
    recordPanelData,
    setRecordPanelData,
    setShouldTableRefresh,
    setSuccessMessage,
  } = useContext(LayoutContext);
  const { closePanel } = useCloseRecordPanel();

  const defaultFields = {
    name: "",
    countryId: "",
    salesRegionId: "",
    territories: "",
  };

  useEffect(() => {
    async function getLSOs() {
      try {
        let response = await fetch(
          DB_URL + "/api/admin/large-sales-organization/search",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({
              ignoreCase: true,
              key: "",
              pageNumber: 1,
              pageSize: 1,
              sortColumn: "",
              sortDirection: "ASC",
              unpaged: true,
            }),
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (response.ok) {
          let usedLSOarray = [];
          result.content.map((lso) =>
            lso.territories.map((territory) => usedLSOarray.push(territory.id))
          );
          // strip out duplicated values:
          let uniqueUsedLSOarray = usedLSOarray.filter(
            (item, index) => usedLSOarray.indexOf(item) === index
          );
          setUsedTerritories(uniqueUsedLSOarray);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    getLSOs();
  }, [user, logoutUser]);

  useEffect(() => {
    async function getCountries() {
      try {
        let response = await fetch(DB_URL + "/api/admin/country/list", {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const formik_country_options = result.map((country) => ({
            value: country.id,
            label: country.name,
          }));
          setCountries(formik_country_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    getCountries();
  }, [user, logoutUser]);

  useEffect(() => {
    async function getSalesRegions() {
      try {
        let response = await fetch(DB_URL + "/api/admin/sales-region/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
          body: JSON.stringify({
            ignoreCase: true,
            key: "",
            pageNumber: 1,
            pageSize: 1,
            sortColumn: "",
            sortDirection: "ASC",
            unpaged: true,
          }),
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const formik_sales_regions_options = result.content.map(
            (sale_region) => ({
              value: sale_region.id,
              label: sale_region.name,
            })
          );
          setSalesRegions(formik_sales_regions_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    getSalesRegions();
  }, [user, logoutUser]);

  useEffect(() => {
    async function getSubRegions() {
      try {
        let response = await fetch(DB_URL + "/api/admin/sub-region/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
          body: JSON.stringify({
            ignoreCase: true,
            key: "",
            pageNumber: 1,
            pageSize: 1,
            sortColumn: "",
            sortDirection: "ASC",
            unpaged: true,
          }),
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const formik_sub_regions_options = result.content.map(
            (sub_region) => ({
              value: sub_region.id,
              label: sub_region.name,
            })
          );
          setSubRegions(formik_sub_regions_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    getSubRegions();
  }, [user, logoutUser]);

  useEffect(() => {
    async function getTerritories() {
      try {
        let response = await fetch(DB_URL + "/api/admin/territory/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
          body: JSON.stringify({
            ignoreCase: true,
            key: "",
            pageNumber: 1,
            pageSize: 1,
            sortColumn: "",
            sortDirection: "ASC",
            unpaged: true,
          }),
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const formik_territory_options = result.content.map((territory) => ({
            value: territory.id,
            label: territory.name,
          }));
          setTerritories(formik_territory_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    getTerritories();
  }, [user, logoutUser]);

  const handleDelete = (e) => {
    // delete from database:
    async function deleteLSO() {
      try {
        let response = await fetch(
          DB_URL +
            `/api/admin/large-sales-organization/${recordPanelData["id"]}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response;

        if (response.ok) {
          setShouldTableRefresh(new Date().getTime());
          setRecordPanelData("");
          setShowRecordPanel(false);
          setSuccessMessage(
            `Record deleted at ${new Date().toLocaleTimeString()}`
          );
        } else {
          toast.error(result.message);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    deleteLSO();
  };

  async function copyPageUrl(id) {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}${window.location.pathname}?record=lso&id=${id}` //change to .href if all records are opened based on URL
      );
      toast("Record URL copied to clipboard");
    } catch (err) {
      // if (process.env.NODE_ENV === "development") throw err
    }
  }

  const initialValues = recordPanelData
    ? {
        ...recordPanelData,
        // territories: recordPanelData.territories[0].id,
        territories: recordPanelData.territories.map((territory) => ({
          value: territory.id,
          label: territory.name,
        })),
      }
    : defaultFields;

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Required";
    } else if (values.name.trim().length < 2) {
      errors.name = "Must be longer";
    }
    if (!values.countryId) {
      errors.countryId = "Required";
    }
    if (!values.salesRegionId) {
      errors.salesRegionId = "Required";
    }
    // if ((values.territories = "" || values.territories.length < 1)) {
    //   errors.territories = "Required";
    // }
    if (!values.territories) {
      errors.territories = "Required";
    }
    return errors;
  };

  const submit = (values) => {
    // prepare for writing to database:
    const { territories } = values;
    values = { ...values, territories: [territories] };

    // if edit row button is clicked (row data in saved in "recordPanelData"):
    if (recordPanelData) {
      setShowRecordPanel(false);
      // UPDATE record to db
      // update record to database:

      const territories_array = [];
      //extract just the id in an array:
      territories.map((territory) => territories_array.push(territory.value));
      values = { ...values, territories: territories_array };

      async function updateLSO() {
        try {
          let response = await fetch(
            DB_URL +
              `/api/admin/large-sales-organization/${recordPanelData["id"]}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
              body: JSON.stringify(values),
            }
          );
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${
                recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      updateLSO();
    } else {
      setShowRecordPanel(false);
      // Create Record to db
      // add record to database:
      // let record = {};
      // fieldsData.map((item) => (record[item.id] = item.value));
      const territories_array = [];
      //extract just the id in an array:
      territories.map((territory) => territories_array.push(territory.value));
      values = { ...values, territories: territories_array };

      async function createLSO() {
        try {
          let response = await fetch(
            DB_URL + "/api/admin/large-sales-organization",
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
              body: JSON.stringify(values),
            }
          );
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${
                recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      createLSO();
    }
  };

  function handleConfirm() {
    setShowConfirmBox((prev) => !prev);
  }

  return (
    <motion.div
      className={styles.formPanel}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <div className={styles.fieldsContainer}>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={submit}
        >
          {(props) => (
            <Form>
              <div className={styles.panelHeader}>
                <AnimatePresence exitBeforeEnter>
                  {showConfirmBox ? (
                    <motion.div
                      initial={{ y: -64 }}
                      animate={{ y: 0 }}
                      exit={{ y: -64 }}
                      className={styles.confirmBox}
                    >
                      <p
                        className={styles.panelLabel}
                        style={{ color: "darkred" }}
                      >
                        Delete record?
                      </p>

                      <Button
                        name="close"
                        // type="button"
                        color="default"
                        onClick={(e) => {
                          e.preventDefault();
                          handleConfirm();
                        }}
                      >
                        {/* <span className="material-icons">close</span> */}
                        Cancel
                      </Button>

                      <Button
                        name="delete"
                        type="button"
                        color="danger"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(e);
                        }}
                      >
                        Confirm
                      </Button>
                    </motion.div>
                  ) : (
                    <>
                      <p className={styles.panelLabel}>
                        {recordPanelData ? "Edit" : "New"} {label}
                      </p>

                      <div className={styles.buttonGroup}>
                        {!recordPanelData && (
                          <Button
                            className={styles.deleteBtn}
                            name="delete"
                            type="button"
                            color="default"
                            onClick={() => props.handleReset()}
                          >
                            Reset
                          </Button>
                        )}

                        {["admin", "rsm"].includes(user.role[0]) &&
                          recordPanelData && (
                            <Button
                              className={styles.deleteBtn}
                              name="delete"
                              type="button"
                              color={sm ? "default" : "danger"}
                              onClick={(e) => {
                                e.preventDefault();
                                handleConfirm();
                              }}
                            >
                              {sm ? (
                                <span className="material-icons">
                                  delete_outline
                                </span>
                              ) : (
                                "Delete"
                              )}
                            </Button>
                          )}

                        <Button
                          name="add"
                          color={sm ? "default" : "primary"}
                          type="submit"
                          disabled={
                            recordPanelData && !props.dirty ? true : false
                          }
                        >
                          {recordPanelData ? "Update" : "Create"}
                        </Button>

                        <Button
                          name="close"
                          type="button"
                          color="default"
                          minWidth="auto"
                          onClick={closePanel}
                        >
                          <CloseIcon style={{ fill: "currentColor" }} />
                        </Button>
                      </div>
                    </>
                  )}
                </AnimatePresence>
              </div>

              <div className={styles.inputList}>
                {recordPanelData && (
                  <div className={styles.bottomButtons}>
                    <Button
                      className={styles.deleteBtn}
                      name="copyLink"
                      type="button"
                      color={"default"}
                      onClick={(e) => {
                        e.preventDefault();
                        copyPageUrl(props.values.id);
                      }}
                      display={"flex"}
                    >
                      <LinkIcon
                        style={{
                          fill: "currentColor",
                          transform: " rotate(-45deg)",
                        }}
                      />
                      Copy Link
                    </Button>
                  </div>
                )}
                <Field as="input" id="name" name="name">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // { value, touched, error, initialValue }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Accounting Entity Name</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="name"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <input
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.inputError
                              : styles.inputValid
                            : styles.input
                        }
                        type="text"
                        placeholder="Type name..."
                        {...field}
                      />
                    </label>
                  )}
                </Field>

                <Field as="select" id="countryId" name="countryId">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // {error, initialError, initalTouched, initialValue, touched, value }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Country</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="countryId"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <Select
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.selectBoxError
                              : styles.selectBoxValid
                            : null
                        }
                        options={countries} //items from db
                        placeholder={"Choose country..."}
                        isClearable={false}
                        isSearchable={true}
                        isMulti={false}
                        value={
                          countries
                            ? countries.find(
                                (option) => option.value === field.value
                              )
                            : ""
                        }
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        onChange={(option) => {
                          form.setFieldValue(field.name, option.value);
                        }}
                      />
                    </label>
                  )}
                </Field>

                <Field as="select" id="salesRegionId" name="salesRegionId">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // {error, initialError, initalTouched, initialValue, touched, value }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Sales Region</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="salesRegionId"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <Select
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.selectBoxError
                              : styles.selectBoxValid
                            : styles.selectBox
                        }
                        options={salesRegions} //items from db
                        placeholder={"Choose sales region..."}
                        isClearable={false}
                        isSearchable={true}
                        isMulti={false}
                        value={
                          salesRegions
                            ? salesRegions.find(
                                (option) => option.value === field.value
                              )
                            : ""
                        }
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        onChange={(option) => {
                          form.setFieldValue(field.name, option.value);
                        }}
                      />
                    </label>
                  )}
                </Field>

                <Field as="select" id="subRegionId" name="subRegionId">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // {error, initialError, initalTouched, initialValue, touched, value }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Sub-Region</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="subRegionId"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <Select
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.selectBoxError
                              : styles.selectBoxValid
                            : styles.selectBox
                        }
                        options={subRegions} //items from db
                        placeholder={"Choose sub-region..."}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={false}
                        value={
                          subRegions
                            ? subRegions.find(
                                (option) => option.value === field.value
                              )
                            : ""
                        }
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        onChange={(option) => {
                          form.setFieldValue(field.name, option?.value);
                        }}
                      />
                    </label>
                  )}
                </Field>

                <Field as="select" id="territories" name="territories">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // {error, initialError, initalTouched, initialValue, touched, value }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Territories (only unused)</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="territories"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <Select
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.selectBoxError
                              : styles.selectBoxValid
                            : null
                        }
                        options={
                          // return only the territories not used by other LSOs:
                          usedTerritories && territories
                            ? territories.filter(
                                (territory) =>
                                  !usedTerritories.includes(territory.value)
                              )
                            : null
                        } //items from db
                        placeholder={"Choose territories..."}
                        isClearable={false}
                        isSearchable={true}
                        isMulti={true}
                        defaultValue={field.value} //read value based on a saved value in db
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        onChange={(option) => {
                          form.setFieldValue(field.name, option);
                        }}
                      />
                    </label>
                  )}
                </Field>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
}

export default RecordPanelLSO;
