//@ts-nocheck
import React from "react";
import App from "./App";
import "./index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { UserContextProvider } from "./context/UserContext";
import { LayoutContextProvider } from "./context/LayoutContext";
import { TempContextProvider } from "./context/TempContext"; // TODO: should be deleted when the values are not needed anymore !

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <React.StrictMode>
    <Router>
      <UserContextProvider>
        {/* TODO: TempContextProvider should be deleted when the values are not needed anymore ! */}
        <TempContextProvider>
          <LayoutContextProvider>
            <App />
          </LayoutContextProvider>
        </TempContextProvider>
      </UserContextProvider>
    </Router>
  </React.StrictMode>
);
