// unauthorized or forbidden request
export function sessionExpirationChecker(response, callback) {
  if (response.status === 401 || response.status === 403) {
    // 401 = Unauthorized
    // 403 = Forbidden
    callback(
      (response.status === 401 ? "Session expired." : "Forbidden operation!") +
        " Sign to continue."
    );
  }
}
