import { useContext } from "react";
import Header from "./components/header";
import Main from "./layout/Main";
import { UserContext } from "./context/UserContext";
import Auth from "./layout/Auth";
import ServerStatus from "./layout/ServerStatus";
import "./App.css";

function App() {
  const { user } = useContext(UserContext);

  // if the URL contains "health", "check" or "healthcheck" then ping the server:
  if (window.location.pathname.search(/health|healthcheck|check/gi) > 0) {
    return <ServerStatus />;
  } else if (!user) {
    // if there is no user stored token then show the login screen:
    return <Auth />;
  } else {
    return (
      <div className="app">
        <Header />
        <Main />
      </div>
    );
  }
}

export default App;
