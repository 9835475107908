import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/history.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { ReactComponent as VpnKey } from "../../assets/icons/vpn_key.svg";
import { ReactComponent as Visibility } from "../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../assets/icons/visibility_off.svg";
import { useState, useEffect, useContext, useMemo } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import styles from "./RecordPanel.module.css";
import { Button } from "../../style/ComponentsLibrary";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCheckMediaQuery from "../../hooks/useCheckMediaQuery";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { UserContext } from "../../context/UserContext";
import { motion, AnimatePresence } from "framer-motion";
import DB_URL from "../../environments";
import { sessionExpirationChecker } from "../../utils/fetch_helpers";
import { useCloseRecordPanel } from ".";

function RecordPanelUsers() {
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [textSecurity, setTextSecurity] = useState(true);
  const [lsoOptions, setLsoOptions] = useState();
  const [salesRegionsOptions, setSalesRegionsOptions] = useState();
  const [gidOptions, setGidOptions] = useState();
  const [showPasswordField, setShowPasswordField] = useState(true);
  const label = "User";
  const sm = useCheckMediaQuery("(max-width: 440px)");
  const {
    setShowRecordPanel,
    recordPanelData,
    setRecordPanelData,
    setShouldTableRefresh,
    setSuccessMessage,
    setShowHistoryTable,
  } = useContext(LayoutContext);
  const { user, logoutUser } = useContext(UserContext);
  const { closePanel } = useCloseRecordPanel();

  useEffect(() => {
    if (recordPanelData) {
      setShowPasswordField(false);
    }
  }, [recordPanelData]);

  const defaultFields = {
    username: "",
    roles: ["rsm"].includes(user?.role[0]) ? "basic" : "",
    salesRegions: "",
    largeSalesOrganizations: [],
    globalIndustries: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  }

  const initialValues = recordPanelData
    ? {
      ...recordPanelData,
      roles: recordPanelData.roles[0].name,
      salesRegions: recordPanelData.salesRegions.map((region) => ({
        value: region.id,
        label: region.name,
      })),
      largeSalesOrganizations: recordPanelData.largeSalesOrganizations.map(
        (lso) => ({
          value: lso.id,
          label: lso.name,
        })
      ),
      globalIndustries: recordPanelData.globalIndustries.map((gid) => ({
        value: gid.id,
        label: gid.name,
      })),
    }
    : defaultFields;

  const [selectedRole, setSelectedRole] = useState(initialValues.roles)

  useEffect(() => {
    async function getSalesRegions() {
      try {
        let response = await fetch(DB_URL + "/api/admin/sales-region/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
          body: JSON.stringify({
            ignoreCase: true,
            key: "",
            pageNumber: 1,
            pageSize: 1,
            sortColumn: "",
            sortDirection: "ASC",
            unpaged: true,
          }),
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const formik_salesRegions_options = result.content.map((region) => ({
            value: region.id,
            label: region.name,
          }));
          setSalesRegionsOptions(formik_salesRegions_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    ["rsm"].includes(selectedRole) && getSalesRegions();
  }, [user, logoutUser, selectedRole]);

  useEffect(() => {
    async function getLSOs() {
      try {
        let response = await fetch(
          DB_URL + "/api/admin/large-sales-organization/search",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({
              ignoreCase: true,
              key: "",
              pageNumber: 1,
              pageSize: 1,
              sortColumn: "",
              sortDirection: "ASC",
              unpaged: true,
            }),
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const formik_lso_options = result.content.map((lso) => ({
            value: lso.id,
            label: lso.name,
          }));
          setLsoOptions(formik_lso_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    ["basic"].includes(selectedRole) && getLSOs();
  }, [user, logoutUser, selectedRole]);

  useEffect(() => {
    async function getGIDs() {
      try {
        let response = await fetch(
          DB_URL + "/api/admin/global-industry/search",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({
              ignoreCase: true,
              key: "",
              pageNumber: 1,
              pageSize: 1,
              sortColumn: "",
              sortDirection: "ASC",
              unpaged: true,
            }),
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          const formik_gid_options = result.content.map((gid) => ({
            value: gid.id,
            label: gid.name,
          }));
          setGidOptions(formik_gid_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    ["gid"].includes(selectedRole) && getGIDs();
  }, [user, logoutUser, selectedRole]);

  const handleDelete = (e) => {
    // delete from database:
    async function deleteUser() {
      try {
        let response = await fetch(
          DB_URL + `/api/admin/user/${recordPanelData["id"]}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({}),
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response;

        if (result.status === 412) {
          toast.error("Action is not permitted!");
          return;
        }

        if (response.ok) {
          setShouldTableRefresh(new Date().getTime());
          setRecordPanelData("");
          setShowRecordPanel(false);
          setSuccessMessage(
            `Record deleted at ${new Date().toLocaleTimeString()}`
          );
        } else {
          toast.error(result.message);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    deleteUser();
  };

  const validate = (values) => {
    let errors = {};
    if (!values.username) errors.username = "Required";
    if (user.role[0] === "admin" && !values.roles) errors.roles = "Required";
    if (values.roles === "rsm" && !values.salesRegions)
      errors.salesRegions = "Required";
    if (values.roles === "basic" && !values.largeSalesOrganizations.length)
      errors.largeSalesOrganizations = "Required";
    if (values.roles === "gid" && !values.globalIndustries)
      errors.globalIndustries = "Required";
    if (!values.firstName) errors.firstName = "Required";
    if (!values.lastName) errors.lastName = "Required";
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (showPasswordField === true && !values.password)
      errors.password = "Required";

    return errors;
  };

  // don't make server request for user roles since we know they are three:
  const rsm_roles_options =
    user.role[0] === "rsm"
      ? [{ value: "basic", label: "Basic" }]
      : [
        { value: "admin", label: "Admininstrator" },
        { value: "rsm", label: "Regional Sales Manager" },
        { value: "basic", label: "Basic" },
        { value: "gid", label: "GID" },
      ];

  async function copyPageUrl(id) {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}${window.location.pathname}?record=user&id=${id}` //change to .href if all records are opened based on URL
      );
      toast("Record URL copied to clipboard");
    } catch (err) {
      // if (process.env.NODE_ENV === "development") throw err
    }
  }

  const submit = (values, actions) => {
    // clean object into pure arrays to not add redundant data into db:

    // for (let one in values) {
    //   let onlyVals = [];
    //   if (Array.isArray(values[one])) {
    //     if (values[one].length > 1) {
    //       values[one].map((item) => onlyVals.push(item.value));
    //       values[one] = onlyVals;
    //     }
    //   }
    // }
    values["enabled"] = true;

    // if edit row button is clicked (row data in saved in "recordPanelData"):
    if (recordPanelData) {
      setShowRecordPanel(false);
      // UPDATE record to db
      // update record to database:
      const { salesRegions, largeSalesOrganizations, globalIndustries, roles } =
        values;
      // extract and send only the first user role:
      switch (roles) {
        case "admin":
          values = {
            ...values,
            roles: [roles],
          };
          // do not send lsos nor gids to database:
          delete values.largeSalesOrganizations;
          delete values.globalIndustries;
          delete values.salesRegions;
          updateUser();
          break;
        case "rsm":
          const salesRegions_array = [];
          //extract just the id in an array:
          salesRegions.map((region) => salesRegions_array.push(region.value));
          values = {
            ...values,
            salesRegions: salesRegions_array,
            roles: [roles],
          };
          // do not send lsos nor gids to database:
          delete values.largeSalesOrganizations;
          delete values.globalIndustries;
          updateUser();
          break;
        case "basic":
          const lso_array = [];
          //extract just the id in an array:
          largeSalesOrganizations.map((lso) => lso_array.push(lso.value));
          values = {
            ...values,
            largeSalesOrganizations: lso_array,
            roles: [roles],
          };
          // do not send gid arrays to database:
          delete values.salesRegions;
          delete values.globalIndustries;
          updateUser();
          break;
        case "gid":
          const gid_array = [];
          //extract just the id in an array:
          globalIndustries.map((gid) => gid_array.push(gid.value));
          values = { ...values, globalIndustries: gid_array, roles: [roles] };
          // do not send lso arrays to database:
          delete values.salesRegions;
          delete values.largeSalesOrganizations;
          updateUser();
          break;
        default:
        //
      }
      async function updateUser() {
        try {
          let response = await fetch(
            DB_URL + `/api/admin/user/${recordPanelData["id"]}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
              body: JSON.stringify(values),
            }
          );
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
    } else {
      setShowRecordPanel(false);
      // Create Record to db
      // add record to database:
      async function createUser() {
        const {
          salesRegions,
          largeSalesOrganizations,
          globalIndustries,
          roles,
        } = values;
        switch (roles) {
          case "admin":
            // do not send lsos nor gids to database:
            delete values.largeSalesOrganizations;
            delete values.globalIndustries;
            delete values.salesRegions;
            values = {
              ...values,
              roles: [roles],
            };
            break;
          case "rsm":
            // do not send lsos nor gids to database:
            delete values.largeSalesOrganizations;
            delete values.globalIndustries;
            const salesRegions_array = [];
            //extract just the id in an array:
            salesRegions.map((region) => salesRegions_array.push(region.value));
            values = {
              ...values,
              salesRegions: salesRegions_array,
              roles: [roles],
            };
            break;
          case "basic":
            const lso_array = [];
            //extract just the id in an array:
            largeSalesOrganizations.map((lso) => lso_array.push(lso.value));
            values = {
              ...values,
              salesRegions: [],
              largeSalesOrganizations: lso_array,
              globalIndustries: [],
              roles: [roles],
            };
            break;
          case "gid":
            const gid_array = [];
            //extract just the id in an array:
            globalIndustries.map((gid) => gid_array.push(gid.value));
            values = {
              ...values,
              salesRegions: [],
              largeSalesOrganizations: [],
              globalIndustries: gid_array,
              roles: [roles],
            };
            break;
          default:
            return;
        }
        try {
          let response = await fetch(DB_URL + "/api/admin/user", {
            method: "PUT",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify(values),
          });
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      createUser();
    }
  };

  function handleConfirm() {
    setShowConfirmBox((prev) => !prev);
  }

  return (
    <motion.div
      className={styles.formPanel}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <div className={styles.fieldsContainer}>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={submit}
        >
          {(props) => (
            <Form>
              <div className={styles.panelHeader}>
                <AnimatePresence exitBeforeEnter>
                  {showConfirmBox ? (
                    <motion.div
                      initial={{ y: -64 }}
                      animate={{ y: 0 }}
                      exit={{ y: -64 }}
                      className={styles.confirmBox}
                    >
                      <p
                        className={styles.panelLabel}
                        style={{ color: "darkred" }}
                      >
                        Delete record?
                      </p>

                      <Button
                        name="close"
                        // type="button"
                        color="default"
                        onClick={(e) => {
                          e.preventDefault();
                          handleConfirm();
                        }}
                      >
                        {/* <span className="material-icons">close</span> */}
                        Cancel
                      </Button>

                      <Button
                        name="delete"
                        type="button"
                        color="danger"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(e);
                        }}
                      >
                        Confirm
                      </Button>
                    </motion.div>
                  ) : (
                    <>
                      <p className={styles.panelLabel}>
                        {recordPanelData ? "Edit" : "New"}{" "}
                        {user.role[0] === "rsm" && " Basic "}
                        {label}
                      </p>

                      <div className={styles.buttonGroup}>
                        {!recordPanelData && (
                          <Button
                            className={styles.deleteBtn}
                            name="delete"
                            type="button"
                            color="default"
                            onClick={() => props.handleReset()}
                          >
                            Reset
                          </Button>
                        )}

                        {["admin", "rsm"].includes(user.role[0]) &&
                          recordPanelData && (
                            <Button
                              className={styles.deleteBtn}
                              name="delete"
                              type="button"
                              color={sm ? "default" : "danger"}
                              onClick={(e) => {
                                e.preventDefault();
                                handleConfirm();
                              }}
                            >
                              {sm ? (
                                <span className="material-icons">
                                  delete_outline
                                </span>
                              ) : (
                                "Delete"
                              )}
                            </Button>
                          )}

                        <Button
                          name="add"
                          color={sm ? "default" : "primary"}
                          type="submit"
                          disabled={
                            recordPanelData && !props.dirty ? true : false
                          }
                        >
                          {recordPanelData ? "Update" : "Create"}
                        </Button>

                        <Button
                          name="close"
                          type="button"
                          color="default"
                          minWidth="auto"
                          onClick={closePanel}
                        >
                          <CloseIcon style={{ fill: "currentColor" }} />
                        </Button>
                      </div>
                    </>
                  )}
                </AnimatePresence>
              </div>

              <div className={styles.inputList}>
                {recordPanelData && (
                  <div className={styles.bottomButtons}>
                    <Button
                      className={styles.deleteBtn}
                      name="delete"
                      type="button"
                      color={"default"}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowHistoryTable(recordPanelData);
                      }}
                      display={"flex"}
                    >
                      <HistoryIcon style={{ fill: "currentColor" }} />
                      See History
                    </Button>
                    <Button
                      className={styles.deleteBtn}
                      name="delete"
                      type="button"
                      color={"default"}
                      onClick={(e) => {
                        e.preventDefault();
                        copyPageUrl(props.values.id);
                      }}
                      display={"flex"}
                    >
                      <LinkIcon
                        style={{
                          fill: "currentColor",
                          transform: " rotate(-45deg)",
                        }}
                      />
                      Copy Link
                    </Button>
                  </div>
                )}
                <Field as="input" id="username" name="username">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // { value, touched, error, initialValue }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Username</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="username"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <input
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.inputError
                              : styles.inputValid
                            : styles.input
                        }
                        type="text"
                        placeholder="Type username..."
                        {...field}
                      />
                    </label>
                  )}
                </Field>
                <Field as="select" id="roles" name="roles">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // {error, initialError, initalTouched, initialValue, touched, value }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Role</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="roles"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <Select
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.selectBoxError
                              : styles.selectBoxValid
                            : null
                        }
                        options={rsm_roles_options} //items from db
                        placeholder={"Choose role..."}
                        isClearable={false}
                        isSearchable={true}
                        isMulti={false}
                        defaultValue={
                          user.role[0] === "rsm" ? rsm_roles_options[0] : null
                        }
                        value={
                          rsm_roles_options
                            ? rsm_roles_options.find(
                              (option) => option.value === props.values.roles
                            )
                            : ""
                        }
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        onChange={(option) => {
                          form.setFieldValue(field.name, option.value);
                          setSelectedRole(option.value)
                        }}
                      />
                    </label>
                  )}
                </Field>

                {props.values.roles === "rsm" && (
                  <Field as="select" id="salesRegions" name="salesRegions">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Sales Regions</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="salesRegions"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : styles.selectBox
                          }
                          options={salesRegionsOptions} //items from db
                          placeholder={"Choose Sales Regions..."}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={true}
                          defaultValue={field.value} //read value based on a saved value in db
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option);
                          }}
                        />
                      </label>
                    )}
                  </Field>
                )}

                {(user.role[0] === "rsm" || props.values.roles === "basic") && (
                  <Field
                    as="select"
                    id="largeSalesOrganizations"
                    name="largeSalesOrganizations"
                  >
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Account Entities</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="largeSalesOrganizations"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : styles.selectBox
                          }
                          options={lsoOptions} //items from db
                          placeholder={"Choose Accounting Entities..."}
                          isClearable={true}
                          isSearchable={true}
                          isMulti={true}
                          defaultValue={field.value} //read value based on a saved value in db
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(options) => {
                            if (options.length) {
                              form.setFieldValue(field.name, options);
                            } else {
                              form.setFieldValue(field.name, [])
                            }
                          }}
                        />
                      </label>
                    )}
                  </Field>
                )}
                {props.values.roles === "gid" && (
                  <Field
                    as="select"
                    id="globalIndustries"
                    name="globalIndustries"
                  >
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Global Industries</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="globalIndustries"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : null
                          }
                          options={gidOptions} //items from db
                          placeholder={"Choose GIDs..."}
                          isClearable={true}
                          isSearchable={true}
                          isMulti={true}
                          defaultValue={field.value} //read value based on a saved value in db
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option);
                          }}
                        />
                      </label>
                    )}
                  </Field>
                )}

                <Field as="input" id="firstName" name="firstName">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // { value, touched, error, initialValue }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>First Name</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="firstName"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <input
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.inputError
                              : styles.inputValid
                            : styles.input
                        }
                        type="text"
                        placeholder="Type first name..."
                        {...field}
                      />
                    </label>
                  )}
                </Field>

                <Field as="input" id="lastName" name="lastName">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // { value, touched, error, initialValue }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Last Name</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="lastName"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <input
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.inputError
                              : styles.inputValid
                            : styles.input
                        }
                        type="text"
                        placeholder="Type last name..."
                        {...field}
                      />
                    </label>
                  )}
                </Field>

                <Field as="input" id="email" name="email">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // { value, touched, error, initialValue }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>E-mail</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="email"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <input
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.inputError
                              : styles.inputValid
                            : styles.input
                        }
                        type="email"
                        placeholder="Type e-mail..."
                        {...field}
                      />
                    </label>
                  )}
                </Field>

                {recordPanelData && !showPasswordField ? (
                  <Button
                    name="close"
                    type="button"
                    color=""
                    margin="0 0 20px 0"
                    onClick={() => setShowPasswordField(true)}
                    display={"flex"}
                  >
                    <VpnKey style={{ fill: "currentColor", marginRight: 6 }} />{" "}
                    Change Password
                  </Button>
                ) : null}

                {!recordPanelData || showPasswordField ? (
                  <label
                    className={styles.label}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 8 }}
                    >
                      <Field as="input" id="password" name="password">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta, // { value, touched, error, initialValue }
                        }) => (
                          <label
                            className={styles.label}
                            style={{ flexBasis: "100%" }}
                          >
                            <div className={styles.fieldHeader}>
                              <span>
                                {recordPanelData
                                  ? "Change Password"
                                  : "Password"}
                              </span>
                              {meta.touched && !meta.error && (
                                <span className={styles.check}>✔ Good</span>
                              )}
                              <ErrorMessage
                                name="password"
                                render={(msg) => (
                                  <div className={styles.errorText}>{msg}</div>
                                )}
                              />
                            </div>
                            <input
                              className={
                                meta.touched
                                  ? meta.error
                                    ? styles.inputError
                                    : styles.inputValid
                                  : styles.input
                              }
                              style={{
                                webkitTextSecurity: textSecurity
                                  ? "disc"
                                  : "none",
                              }}
                              //   type="password"
                              placeholder="Type password..."
                              {...field}
                            />
                          </label>
                        )}
                      </Field>
                      {props.values.password?.length > 0 && (
                        <button
                          disabled={props.values.password ? false : true}
                          title={
                            textSecurity ? "Show password" : "Hide password"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setTextSecurity((prev) => !prev);
                          }}
                          style={{ minWidth: "10%", margin: 1, height: 38 }}
                        >
                          <span
                            className="material-icons"
                            style={{ marginTop: 4 }}
                          >
                            {textSecurity ? (
                              <Visibility style={{ fill: "currentColor" }} />
                            ) : (
                              <VisibilityOff style={{ fill: "currentColor" }} />
                            )}
                          </span>
                        </button>
                      )}
                    </div>
                  </label>
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
}

export default RecordPanelUsers;
