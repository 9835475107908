import { useContext, Suspense, lazy } from "react";
import styles from "./Main.module.css";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { LayoutContext } from "../context/LayoutContext";
import { UserContext } from "../context/UserContext";
import { ToastContainer, Zoom } from "react-toastify";
import RecordPanelRouter from "../components/RecordPanel";
import useFetchRecord from "../hooks/useFetchRecord";
import usePage from "../hooks/usePage";
import { Loading } from "../utils/loadingHelper";
const HistoryTable = lazy(() => import("../components/Table/HistoryTable"));
const OrderIncome = lazy(() => import("../pages/OrderIncome"));
const LargeOrders = lazy(() => import("../pages/LargeOrders"));
const AnnualBudget = lazy(() => import("../pages/AnnualBudget"));
const LSO = lazy(() => import("../pages/LSO"));
const Users = lazy(() => import("../pages/Users"));
const GID = lazy(() => import("../pages/GID"));
const SalesRegions = lazy(() => import("../pages/SalesRegions"));
const SubRegions = lazy(() => import("../pages/SubRegions"));
const Territory = lazy(() => import("../pages/Territory"));
const Countries = lazy(() => import("../pages/Countries"));
const WinProbability = lazy(() => import("../pages/WinProbability"));
const Dashboard = lazy(() => import("../pages/Dashboard/index"));
const Sync = lazy(() => import("../pages/Sync"));

const Main = () => {
  usePage();
  useFetchRecord();
  const {
    settingsBox,
    setSettingsBox,
    addNewDropdown,
    setAddNewDropdown,
    mobileMenu,
    setMobileMenu,
    showRecordPanel,
    showHistoryTable,
    successMessage,
  } = useContext(LayoutContext);

  const { user } = useContext(UserContext);

  let location = useLocation();

  function handleCloseNavBoxes() {
    if (mobileMenu) {
      setMobileMenu(false);
    }
    if (settingsBox) {
      setSettingsBox(false);
    }
    if (addNewDropdown) {
      setAddNewDropdown(false);
    }
  }

  return (
    <main
      className={styles.main}
      style={
        location.pathname === "/dashboard"
          ? {
              display: "block",
              flex: "auto",
              overflowY: "auto",
            }
          : {
              display: "flex",
              flex: 1,
              flexDirection: "row",
            }
      }
      onClick={handleCloseNavBoxes}
    >
      {successMessage && (
        <ToastContainer
          position="bottom-right"
          autoClose={3500}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Zoom}
        />
      )}

      {showRecordPanel && <RecordPanelRouter />}

      <Suspense fallback={<Loading text={"Getting data..."} />}>
        {showHistoryTable && <HistoryTable />}
        <Switch>
          <Route exact path="/">
            {["gid"].includes(user.role[0]) ? (
              <Redirect to="/large-orders" />
            ) : (
              <Redirect to="/dashboard" />
            )}
          </Route>
          <Route path="/dashboard">
            {["gid"].includes(user.role[0]) ? (
              <Redirect to="/" />
            ) : (
              <Dashboard />
            )}
          </Route>
          <Route path="/order-income">
            {["gid"].includes(user.role[0]) ? (
              <Redirect to="/dashboard" />
            ) : (
              <OrderIncome />
            )}
          </Route>
          <Route path="/large-orders">
            <LargeOrders />
          </Route>
          <Route path="/annual-budget">
            {!["admin", "rsm"].includes(user.role[0]) ? (
              <Redirect to="/dashboard" />
            ) : (
              <AnnualBudget />
            )}
          </Route>
          <Route path="/lso">
            {!["admin"].includes(user.role[0]) ? <Redirect to="/" /> : <LSO />}
          </Route>
          <Route path="/gid">
            {!["admin"].includes(user.role[0]) ? <Redirect to="/" /> : <GID />}
          </Route>
          <Route path="/sales-regions">
            {!["admin"].includes(user.role[0]) ? (
              <Redirect to="/" />
            ) : (
              <SalesRegions />
            )}
          </Route>
          <Route path="/sub-regions">
            {!["admin"].includes(user.role[0]) ? (
              <Redirect to="/" />
            ) : (
              <SubRegions />
            )}
          </Route>
          <Route path="/countries">
            {!["admin"].includes(user.role[0]) ? (
              <Redirect to="/" />
            ) : (
              <Countries />
            )}
          </Route>
          <Route path="/territory">
            {!["admin"].includes(user.role[0]) ? (
              <Redirect to="/" />
            ) : (
              <Territory />
            )}
          </Route>
          <Route path="/win-probability">
            {!["admin"].includes(user.role[0]) ? (
              <Redirect to="/" />
            ) : (
              <WinProbability />
            )}
          </Route>
          <Route path="/users">
            {!["admin", "rsm"].includes(user.role[0]) ? (
              <Redirect to="/" />
            ) : (
              <Users />
            )}
          </Route>
          <Route path="/sync">
            <Sync />
          </Route>
        </Switch>
      </Suspense>
    </main>
  );
};

export default Main;
