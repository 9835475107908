export const booleanOptions = [
  { value: "YES", label: "Yes" },
  { value: "NO", label: "No" },
];

export const monthsOptions = [
  { value: 1, label: "January", shortLabel: "Jan" },
  { value: 2, label: "February", shortLabel: "Feb" },
  { value: 3, label: "March", shortLabel: "Mar" },
  { value: 4, label: "April", shortLabel: "Apr" },
  { value: 5, label: "May", shortLabel: "May" },
  { value: 6, label: "June", shortLabel: "Jun" },
  { value: 7, label: "July", shortLabel: "Jul" },
  { value: 8, label: "August", shortLabel: "Aug" },
  { value: 9, label: "September", shortLabel: "Sep" },
  { value: 10, label: "October", shortLabel: "Oct" },
  { value: 11, label: "November", shortLabel: "Nov" },
  { value: 12, label: "December", shortLabel: "Dec" },
];

export const classificationOptions = [
  { value: "NONE", label: "None" },
  { value: "DIRECT_ORDER", label: "Direct order" },
  { value: "NO_LINEOFSIGHT", label: "No line of sight" },
];

export const phaseOptions = [
  { value: "168600000", label: "01-Inquiry in Process" },
  { value: "168600001", label: "02-Offer in Preparation" },
  { value: "168600002", label: "03-Classification of Quote" },
  { value: "168600003", label: "04-Followed by Outside Sales" },
  { value: "168600004", label: "05-Order Confirmed" },
];
export const phaseOptionsObject = {};
phaseOptions.map((option) => (phaseOptionsObject[option.value] = option.label));
