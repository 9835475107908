import { useCallback } from "react";
import { createContext, useState, useEffect } from "react";
import DB_URL from "../environments";

const UserContext = createContext();

function UserContextProvider(props) {
  const localUser = JSON.parse(localStorage.getItem("user")) || null;
  const [user, setUser] = useState(localUser);
  const [loginMessage, setLoginMessage] = useState("");

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  const logoutUser = useCallback(
    async (message) => {
      if (user) {
        let response = await fetch(DB_URL + "/api/auth/logout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${user.token}`,
          },
        });

        if (response) {
          localStorage.removeItem("user");
          setUser();
          setLoginMessage(message);
        }
      }
    },
    [user]
  );

  return (
    <UserContext.Provider
      value={{ user, setUser, logoutUser, loginMessage, setLoginMessage }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export { UserContextProvider, UserContext };
