import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { useState, useContext } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import styles from "./RecordPanel.module.css";
import { Button } from "../../style/ComponentsLibrary";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCheckMediaQuery from "../../hooks/useCheckMediaQuery";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import DB_URL from "../../environments";
import { sessionExpirationChecker } from "../../utils/fetch_helpers";
import { useCloseRecordPanel } from ".";

function RecordPanelSubRegion() {
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const { user, logoutUser } = useContext(UserContext);
  const label = "Sub-Regions";
  const sm = useCheckMediaQuery("(max-width: 440px)");
  const {
    setShowRecordPanel,
    recordPanelData,
    setRecordPanelData,
    setShouldTableRefresh,
    setSuccessMessage,
  } = useContext(LayoutContext);
  const { closePanel } = useCloseRecordPanel();

  const defaultFields = {
    name: "",
    shortName: "",
  };

  const handleDelete = (e) => {
    // delete from database:
    async function deleteSubRegion() {
      try {
        let response = await fetch(
          DB_URL + `/api/admin/sub-region/${recordPanelData["id"]}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response;

        if (response.ok) {
          setShouldTableRefresh(new Date().getTime());
          setRecordPanelData("");
          setShowRecordPanel(false);
          setSuccessMessage(
            `Record deleted at ${new Date().toLocaleTimeString()}`
          );
        } else {
          toast.error(result.message);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    deleteSubRegion();
  };

  async function copyPageUrl(id) {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}${window.location.pathname}?record=sub-region&id=${id}` //change to .href if all records are opened based on URL
      );
      toast("Record URL copied to clipboard");
    } catch (err) {
      // if (process.env.NODE_ENV === "development") throw err
    }
  }

  const initialValues = recordPanelData ? recordPanelData : defaultFields;

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Required";
    } else if (values.name.trim().length < 2) {
      errors.name = "Must be longer";
    }

    if (!values.shortName) {
      errors.shortName = "Required";
    }

    return errors;
  };

  const submit = (values, actions) => {
    // prepare for writing to database:

    // if edit row button is clicked (row data in saved in "recordPanelData"):
    if (recordPanelData) {
      setShowRecordPanel(false);
      // UPDATE record to db
      // update record to database:

      async function updateSubRegion() {
        try {
          let response = await fetch(
            DB_URL + `/api/admin/sub-region/${recordPanelData["id"]}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
              body: JSON.stringify(values),
            }
          );
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${
                recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      updateSubRegion();
    } else {
      // Create Record to db
      // add record to database:
      async function createSubRegion() {
        try {
          let response = await fetch(DB_URL + "/api/admin/sub-region", {
            method: "PUT",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify(values),
          });
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${
                recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      createSubRegion();
    }
  };

  function handleConfirm() {
    setShowConfirmBox((prev) => !prev);
  }

  return (
    <motion.div
      className={styles.formPanel}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <div className={styles.fieldsContainer}>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={submit}
        >
          {(props) => (
            <Form>
              <div className={styles.panelHeader}>
                <AnimatePresence exitBeforeEnter>
                  {showConfirmBox ? (
                    <motion.div
                      initial={{ y: -64 }}
                      animate={{ y: 0 }}
                      exit={{ y: -64 }}
                      className={styles.confirmBox}
                    >
                      <p
                        className={styles.panelLabel}
                        style={{ color: "darkred" }}
                      >
                        Delete record?
                      </p>

                      <Button
                        name="close"
                        // type="button"
                        color="default"
                        onClick={(e) => {
                          e.preventDefault();
                          handleConfirm();
                        }}
                      >
                        {/* <span className="material-icons">close</span> */}
                        Cancel
                      </Button>

                      <Button
                        name="delete"
                        type="button"
                        color="danger"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(e);
                        }}
                      >
                        Confirm
                      </Button>
                    </motion.div>
                  ) : (
                    <>
                      <p className={styles.panelLabel}>
                        {recordPanelData ? "Edit" : "New"} {label}
                      </p>

                      <div className={styles.buttonGroup}>
                        {!recordPanelData && (
                          <Button
                            className={styles.deleteBtn}
                            name="delete"
                            type="button"
                            color="default"
                            onClick={() => props.handleReset()}
                          >
                            Reset
                          </Button>
                        )}

                        {["admin", "rsm"].includes(user.role[0]) &&
                          recordPanelData && (
                            <Button
                              className={styles.deleteBtn}
                              name="delete"
                              type="button"
                              color={sm ? "default" : "danger"}
                              onClick={(e) => {
                                e.preventDefault();
                                handleConfirm();
                              }}
                            >
                              {sm ? (
                                <span className="material-icons">
                                  delete_outline
                                </span>
                              ) : (
                                "Delete"
                              )}
                            </Button>
                          )}

                        <Button
                          name="add"
                          color={sm ? "default" : "primary"}
                          type="submit"
                          disabled={
                            recordPanelData && !props.dirty ? true : false
                          }
                        >
                          {recordPanelData ? "Update" : "Create"}
                        </Button>

                        <Button
                          name="close"
                          type="button"
                          color="default"
                          minWidth="auto"
                          onClick={closePanel}
                        >
                          <CloseIcon style={{ fill: "currentColor" }} />
                        </Button>
                      </div>
                    </>
                  )}
                </AnimatePresence>
              </div>

              <div className={styles.inputList}>
                {recordPanelData && (
                  <div className={styles.bottomButtons}>
                    <Button
                      className={styles.deleteBtn}
                      name="copyLink"
                      type="button"
                      color={"default"}
                      onClick={(e) => {
                        e.preventDefault();
                        copyPageUrl(props.values.id);
                      }}
                      display={"flex"}
                    >
                      <LinkIcon
                        style={{
                          fill: "currentColor",
                          transform: " rotate(-45deg)",
                        }}
                      />
                      Copy Link
                    </Button>
                  </div>
                )}
                <Field as="input" id="name" name="name">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // { value, touched, error, initialValue }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Sales Region Name</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="name"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <input
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.inputError
                              : styles.inputValid
                            : styles.input
                        }
                        type="text"
                        placeholder="Type name..."
                        {...field}
                      />
                    </label>
                  )}
                </Field>

                <Field as="input" id="shortName" name="shortName">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // (The Formik bag) also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // { value, touched, error, initialValue }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Short Name</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="shortName"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <input
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.inputError
                              : styles.inputValid
                            : styles.input
                        }
                        type="text"
                        placeholder="Type short name..."
                        {...field}
                      />
                    </label>
                  )}
                </Field>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
}

export default RecordPanelSubRegion;
