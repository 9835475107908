import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/history.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { useContext, useState, useEffect } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import styles from "./RecordPanel.module.css";
import { Button } from "../../style/ComponentsLibrary";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCheckMediaQuery from "../../hooks/useCheckMediaQuery";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { UserContext } from "../../context/UserContext";
import { motion, AnimatePresence } from "framer-motion";
import DB_URL from "../../environments";
import { sessionExpirationChecker } from "../../utils/fetch_helpers";
import { useCloseRecordPanel } from ".";

const browserLang = navigator.language;

function RecordPanelAnnualBudget() {
  const label = "Annual Budget";
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [lsoOptions, setLsoOptions] = useState();
  const { user, logoutUser } = useContext(UserContext);
  const sm = useCheckMediaQuery("(max-width: 440px)");
  const {
    setShowRecordPanel,
    recordPanelData,
    setRecordPanelData,
    setShowHistoryTable,
    setShouldTableRefresh,
    setSuccessMessage,
  } = useContext(LayoutContext);
  const { closePanel } = useCloseRecordPanel();

  const defaultFields = {
    year: "",
    largeSalesOrganizationId: "",
    budget: "",
    budgetMonth1: "",
    budgetMonth2: "",
    budgetMonth3: "",
    budgetMonth4: "",
    budgetMonth5: "",
    budgetMonth6: "",
    budgetMonth7: "",
    budgetMonth8: "",
    budgetMonth9: "",
    budgetMonth10: "",
    budgetMonth11: "",
    budgetMonth12: "",
    lastModifiedDate: "",
    lastModifiedBy: "",
    lastModifiedByUser: "",
  };

  useEffect(() => {
    async function getLSOs() {
      try {
        let response = await fetch(
          DB_URL + "/api/admin/large-sales-organization/search",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify({
              ignoreCase: true,
              key: "",
              pageNumber: 1,
              pageSize: 1,
              sortColumn: "",
              sortDirection: "ASC",
              unpaged: true,
            }),
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (response.ok) {
          const lso_options = result.content.map((territory) => ({
            value: territory.id,
            label: territory.name,
          }));
          setLsoOptions(lso_options);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }

    getLSOs();
  }, [user, logoutUser]);

  const handleClickClose = () => {
    setShowRecordPanel(false);
    setRecordPanelData("");
  };

  const handleDelete = (e) => {
    // delete from database:
    e.preventDefault();
    async function deleteAnnualBudget() {
      try {
        let response = await fetch(
          DB_URL + `/api/admin/annual-budget/${recordPanelData["id"]}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
          }
        );
        sessionExpirationChecker(response, logoutUser);
        let result = await response;

        if (response.ok) {
          setShouldTableRefresh(new Date().getTime());
          setRecordPanelData("");
          setShowRecordPanel(false);
          setSuccessMessage(
            `Record deleted at ${new Date().toLocaleTimeString()}`
          );
        } else {
          toast.error(result.message);
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    deleteAnnualBudget();
  };

  const initialValues = recordPanelData
    ? {
        ...recordPanelData,
        budget:
          recordPanelData.budgetMonth1 +
          recordPanelData.budgetMonth2 +
          recordPanelData.budgetMonth3 +
          recordPanelData.budgetMonth4 +
          recordPanelData.budgetMonth5 +
          recordPanelData.budgetMonth6 +
          recordPanelData.budgetMonth7 +
          recordPanelData.budgetMonth8 +
          recordPanelData.budgetMonth9 +
          recordPanelData.budgetMonth10 +
          recordPanelData.budgetMonth11 +
          recordPanelData.budgetMonth12,
      }
    : defaultFields;

  const submit = (values, { setSubmitting, resetForm }) => {
    // if edit row button is clicked (row data in saved in "recordPanelData"):

    if (recordPanelData) {
      setShowRecordPanel(false);
      // UPDATE record to db

      // refactor values for db:
      const updateValues = { ...values };
      delete updateValues.lastModifiedDate;
      delete updateValues.lastModifiedByUser;
      delete updateValues.budget;

      async function updateAB() {
        try {
          let response = await fetch(
            DB_URL + `/api/admin/annual-budget/${recordPanelData["id"]}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
              body: JSON.stringify(updateValues),
            }
          );
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${
                recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      updateAB();
    } else {
      // Create Record to db
      // add record to database:

      // modify values for sending to database:
      const updateValues = { ...values };
      delete updateValues.lastModifiedBy;
      delete updateValues.lastModifiedDate;

      async function createAB() {
        delete updateValues.budgetMonth1;
        delete updateValues.budgetMonth2;
        delete updateValues.budgetMonth3;
        delete updateValues.budgetMonth4;
        delete updateValues.budgetMonth5;
        delete updateValues.budgetMonth6;
        delete updateValues.budgetMonth7;
        delete updateValues.budgetMonth8;
        delete updateValues.budgetMonth9;
        delete updateValues.budgetMonth10;
        delete updateValues.budgetMonth11;
        delete updateValues.budgetMonth12;
        try {
          let response = await fetch(DB_URL + "/api/admin/annual-budget", {
            method: "PUT",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify(updateValues),
          });
          sessionExpirationChecker(response, logoutUser);
          let result = await response.json();

          if (response.ok) {
            setShouldTableRefresh(new Date().getTime());
            setRecordPanelData("");
            setShowRecordPanel(false);
            setSuccessMessage(
              `${label} ${
                recordPanelData ? "updated" : "created"
              } at ${new Date().toLocaleTimeString()}`
            );
          } else {
            toast.error(result.message);
          }
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      createAB();
    }
  };

  const validate = (values) => {
    let errors = {};

    if (!values.year) errors.year = "Required";
    if (!values.largeSalesOrganizationId)
      errors.largeSalesOrganizationId = "Required";
    if (!values.budget) errors.budget = "Required";

    return errors;
  };

  // handle allowed years for selection field
  const thisYear = new Date().getFullYear();
  const years_options = [];
  for (let year = thisYear; year <= thisYear + 20; year++) {
    years_options.push({ value: year, label: year });
  }

  async function copyPageUrl(id) {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}${window.location.pathname}?record=annual-budget&id=${id}` //change to .href if all records are opened based on URL
      );
      toast("Record URL copied to clipboard");
    } catch (err) {
      // if (process.env.NODE_ENV === "development") throw err
    }
  }

  function handleConfirm() {
    setShowConfirmBox((prev) => !prev);
  }

  return (
    <motion.div
      className={styles.formPanel}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <div className={styles.fieldsContainer}>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={submit}
        >
          {(props) => (
            <Form>
              <div className={styles.panelHeader}>
                <AnimatePresence exitBeforeEnter>
                  {showConfirmBox ? (
                    <motion.div
                      initial={{ y: -64 }}
                      animate={{ y: 0 }}
                      exit={{ y: -64 }}
                      className={styles.confirmBox}
                    >
                      <p
                        className={styles.panelLabel}
                        style={{ color: "darkred" }}
                      >
                        Delete record?
                      </p>

                      <Button
                        name="close"
                        // type="button"
                        color="default"
                        onClick={(e) => {
                          e.preventDefault();
                          handleConfirm();
                        }}
                      >
                        {/* <span className="material-icons">close</span> */}
                        Cancel
                      </Button>

                      <Button
                        name="delete"
                        type="button"
                        color="danger"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(e);
                        }}
                      >
                        Confirm
                      </Button>
                    </motion.div>
                  ) : (
                    <>
                      <p className={styles.panelLabel}>
                        {recordPanelData ? "Edit" : "New"} {label}
                      </p>

                      <div className={styles.buttonGroup}>
                        {!recordPanelData && (
                          <Button
                            className={styles.deleteBtn}
                            name="delete"
                            type="button"
                            color="default"
                            onClick={() => props.handleReset()}
                          >
                            Reset
                          </Button>
                        )}

                        {["admin", "rsm"].includes(user.role[0]) &&
                          recordPanelData && (
                            <Button
                              className={styles.deleteBtn}
                              name="delete"
                              type="button"
                              color={sm ? "default" : "danger"}
                              onClick={(e) => {
                                e.preventDefault();
                                handleConfirm();
                              }}
                            >
                              {sm ? (
                                <span className="material-icons">
                                  delete_outline
                                </span>
                              ) : (
                                "Delete"
                              )}
                            </Button>
                          )}

                        <Button
                          name="add"
                          color={sm ? "default" : "primary"}
                          type="submit"
                          disabled={
                            recordPanelData && !props.dirty ? true : false
                          }
                        >
                          {recordPanelData ? "Update" : "Create"}
                        </Button>

                        <Button
                          name="close"
                          type="button"
                          color="default"
                          minWidth="auto"
                          onClick={closePanel}
                        >
                          <CloseIcon style={{ fill: "currentColor" }} />
                        </Button>
                      </div>
                    </>
                  )}
                </AnimatePresence>
              </div>

              <div className={styles.inputList}>
                {recordPanelData && (
                  <div className={styles.bottomButtons}>
                    <Button
                      className={styles.deleteBtn}
                      name="history"
                      type="button"
                      color={"default"}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowHistoryTable(recordPanelData);
                      }}
                      display={"flex"}
                    >
                      <HistoryIcon style={{ fill: "currentColor" }} />
                      See History
                    </Button>
                    <Button
                      className={styles.deleteBtn}
                      name="copyLink"
                      type="button"
                      color={"default"}
                      onClick={(e) => {
                        e.preventDefault();
                        copyPageUrl(props.values.id);
                      }}
                      display={"flex"}
                    >
                      <LinkIcon
                        style={{
                          fill: "currentColor",
                          transform: " rotate(-45deg)",
                        }}
                      />
                      Copy Link
                    </Button>
                  </div>
                )}
                <Field
                  as="select"
                  id="largeSalesOrganizationId"
                  name="largeSalesOrganizationId"
                >
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // {error, initialError, initalTouched, initialValue, touched, value }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Accounting Entity</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        <ErrorMessage
                          name="largeSalesOrganizationId"
                          render={(msg) => (
                            <div className={styles.errorText}>{msg}</div>
                          )}
                        />
                      </div>
                      <Select
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.selectBoxError
                              : styles.selectBoxValid
                            : styles.selectBox
                        }
                        options={lsoOptions} //items from db
                        placeholder={"Choose Accounting Entity..."}
                        isClearable={false}
                        isSearchable={true}
                        isMulti={false}
                        value={
                          lsoOptions
                            ? lsoOptions.find(
                                (option) => option.value === field.value
                              )
                            : ""
                        }
                        // pre-select globally selected LSO when create new record
                        // defaultValue={{
                        //   value: globalSelectedLSO,
                        //   label: globalSelectedLSO.toString(),
                        // }}
                        onBlur={() => {
                          form.setFieldTouched(field.name, true);
                        }}
                        onChange={(option) => {
                          form.setFieldValue(field.name, option.value);
                        }}
                      />
                    </label>
                  )}
                </Field>

                {recordPanelData ? (
                  <Field as="select" id="year" name="year">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Year</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="year"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <input
                          disabled={true}
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.inputError
                                : styles.inputValid
                              : styles.input
                          }
                          type="number"
                          placeholder="Type budget..."
                          {...field}
                        />
                      </label>
                    )}
                  </Field>
                ) : (
                  <Field as="select" id="year" name="year">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta, // {error, initialError, initalTouched, initialValue, touched, value }
                    }) => (
                      <label className={styles.label}>
                        <div className={styles.fieldHeader}>
                          <span>Year</span>
                          {meta.touched && !meta.error && (
                            <span className={styles.check}>✔ Good</span>
                          )}
                          <ErrorMessage
                            name="year"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        </div>
                        <Select
                          className={
                            meta.touched
                              ? meta.error
                                ? styles.selectBoxError
                                : styles.selectBoxValid
                              : styles.selectBox
                          }
                          options={years_options} //items from db
                          placeholder={"Choose year..."}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          value={
                            years_options
                              ? years_options.find(
                                  (option) => option.value === field.value
                                )
                              : ""
                          }
                          onBlur={() => {
                            form.setFieldTouched(field.name, true);
                          }}
                          onChange={(option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                        />
                      </label>
                    )}
                  </Field>
                )}

                <Field as="input" id="budget" name="budget">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta, // {error, initialError, initalTouched, initialValue, touched, value }
                  }) => (
                    <label className={styles.label}>
                      <div className={styles.fieldHeader}>
                        <span>Budget (€)</span>
                        {meta.touched && !meta.error && (
                          <span className={styles.check}>✔ Good</span>
                        )}
                        {!recordPanelData && (
                          <ErrorMessage
                            name="budget"
                            render={(msg) => (
                              <div className={styles.errorText}>{msg}</div>
                            )}
                          />
                        )}
                      </div>
                      <input
                        disabled={recordPanelData}
                        className={
                          meta.touched
                            ? meta.error
                              ? styles.inputError
                              : styles.inputValid
                            : styles.input
                        }
                        type="number"
                        placeholder="Type budget..."
                        {...field}
                      />
                    </label>
                  )}
                </Field>

                {recordPanelData && (
                  <>
                    <Field as="input" id="budgetMonth1" name="budgetMonth1">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>January Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth2" name="budgetMonth2">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>February Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth3" name="budgetMonth3">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>March Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth4" name="budgetMonth4">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>April Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth5" name="budgetMonth5">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>May Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth6" name="budgetMonth6">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>June Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth7" name="budgetMonth7">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>July Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth8" name="budgetMonth8">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>August Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth9" name="budgetMonth9">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>September Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth10" name="budgetMonth10">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>October Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth11" name="budgetMonth11">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>November Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    <Field as="input" id="budgetMonth12" name="budgetMonth12">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // : { touched, errors }, everything: also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta, // {error, initialError, initalTouched, initialValue, touched, value }
                      }) => (
                        <label className={styles.label}>
                          <div className={styles.fieldHeader}>
                            <span>December Budget (€)</span>
                          </div>
                          <input
                            className={styles.input}
                            type="number"
                            {...field}
                          />
                        </label>
                      )}
                    </Field>
                    {recordPanelData && (
                      <>
                        <label
                          className={styles.label}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "20px",
                          }}
                        >
                          Last modified on
                          <input
                            disabled={true}
                            className={styles.input}
                            id={"lastModifiedDate"}
                            name={"lastModifiedDate"}
                            type={"text"}
                            value={new Date(
                              props.values.lastModifiedDate
                            )?.toLocaleDateString(browserLang)}
                          />
                        </label>
                        <label
                          className={styles.label}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "20px",
                          }}
                        >
                          Last modified by
                          <input
                            disabled={true}
                            className={styles.input}
                            id={
                              ["admin", "rsm"].includes(user.role[0])
                                ? "lastModifiedBy"
                                : "lastModifiedByUser"
                            }
                            name={
                              ["admin", "rsm"].includes(user.role[0])
                                ? "lastModifiedBy"
                                : "lastModifiedByUser"
                            }
                            type={"text"}
                            value={props.values.lastModifiedByUser}
                          />
                        </label>
                      </>
                    )}
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
}

export default RecordPanelAnnualBudget;
