import React, { createContext, useState } from "react";

export const TempContext = createContext();

export function TempContextProvider({ children }) {
  const [
    annualEOYForecastVariationsChartVisibility,
    setAnnualEOYForecastVariationsChartVisibility,
  ] = useState(false);

  return (
    <TempContext.Provider
      value={{
        annualEOYForecastVariationsChartVisibility,
        setAnnualEOYForecastVariationsChartVisibility,
      }}
    >
      {children}
    </TempContext.Provider>
  );
}
