import styles from "./ComponentsLibrary.module.css";
import PropTypes from "prop-types";

export function Button({
  children,
  name,
  type,
  onClick,
  color,
  margin = 0,
  display = "flex",
  dataCy = null,
  disabled = false,
  minWidth = 100,
  minHeight = 36,
}) {
  let btnClass;
  switch (color) {
    case "primary":
      btnClass = styles.primaryBtn;
      break;
    case "secondary":
      btnClass = styles.secondaryBtn;
      break;
    case "danger":
      btnClass = styles.dangerBtn;
      break;
    default:
      btnClass = styles.defaultBtn;
  }
  return (
    <button
      data-cy={dataCy}
      name={name}
      type={type}
      onClick={onClick}
      className={btnClass}
      style={{
        display: display,
        justifyContent: "center",
        alignItems: "center",
        margin: margin,
        minWidth: minWidth,
        minHeight: minHeight,
        backgroundColor: disabled ? "#dadcdf" : null,
        color: disabled ? "gray" : null,
        cursor: disabled ? "not-allowed" : null,
      }}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
export function Input({
  children,
  name = null,
  type = "text",
  placeholder,
  required,
  onChange,
  dataCy = null,
  autoFocus = false,
  disabled = false,
}) {
  return (
    <input
      autoFocus={autoFocus}
      data-cy={dataCy}
      name={name}
      type={type}
      placeholder={placeholder}
      required={required}
      className={styles.input}
      onChange={onChange}
      disabled={disabled}
    >
      {children}
    </input>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
};
