import BeatLoader from "react-spinners/BeatLoader";

const style = {
  height: 20,
  width: 60,
  marginRight: 8,
};

export default function ButtonAnimation({noMargin = false}) {
  return (
    <div style={{ ...style, marginRight: noMargin ? 0 : null}}>
      <BeatLoader />
    </div>
  );
}
