import { useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import DB_URL from "../environments";
import { Button } from "../style/ComponentsLibrary";
import styles from "./ExportData.module.css";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import ButtonAnimation from "./ButtonAnimation";
import saveAs from "file-saver";
import { ToastContainer } from "react-toastify";
import { Zoom } from "react-toastify";
import { toast } from "react-toastify";
import { sessionExpirationChecker } from "../utils/fetch_helpers";
import fetchWithTimeout from "../utils/fetchWithTimeout";

export default function ExportData({ isModalOpen, setIsModalOpen }) {
  const [isOrderIncomeLoading, setIsOrderIncomeLoading] = useState(false);
  const [isOrderIncomeHistoryLoading, setIsOrderIncomeHistoryLoading] =
    useState(false);
  const [isLargeOrdersHistoryLoading, setIsLargeOrdersHistoryLoading] =
    useState(false);
  const [isAnnualBudgetHistoryLoading, setIsAnnualBudgetHistoryLoading] =
    useState(false);
  const [isLogLoading, setIsLogLoading] = useState(false);
  const { user, logoutUser } = useContext(UserContext);

  function getDateAndTime() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    let time =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day) +
      " " +
      (hour < 10 ? "0" + hour : hour) +
      "-" +
      (min < 10 ? "0" + min : min) +
      "-" +
      (sec < 10 ? "0" + sec : sec);
    return time;
  }

  async function fetchOrderIncomeData() {
    try {
      const response = await fetch(DB_URL + "/api/order-income/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + user.token,
        },
      });
      sessionExpirationChecker(response, logoutUser);
      let result = await response.blob();

      if (result) {
        saveAs(result, `Order Income ${getDateAndTime()}.csv`);
      }
    } catch (error) {
      // console.log(error);
      toast.error("Could not receive Order Income data. Please retry later.");
    } finally {
      setIsOrderIncomeLoading(false);
    }
  }

  async function fetchOrderIncomeHistoryData() {
    try {
      const response = await fetchWithTimeout(
        DB_URL + "/api/order-income/export-history",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
        }
      );
      sessionExpirationChecker(response, logoutUser);
      let result = await response.blob();

      if (result) {
        saveAs(result, `Order Income History ${getDateAndTime()}.csv`);
      }
    } catch (error) {
      // console.log(error);
      toast.error(
        "Could not receive Order Income History data. Please retry later."
      );
    } finally {
      setIsOrderIncomeHistoryLoading(false);
    }
  }

  async function fetchLargeOrderHistoryData() {
    try {
      const response = await fetchWithTimeout(
        DB_URL + "/api/large-order/export-history",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
        }
      );
      sessionExpirationChecker(response, logoutUser);
      let result = await response.blob();

      if (result) {
        saveAs(result, `Large Order Potential History ${getDateAndTime()}.csv`);
      }
    } catch (error) {
      // console.log(error);
      toast.error(
        "Could not receive Large Order Potential History data. Please retry later."
      );
    } finally {
      setIsLargeOrdersHistoryLoading(false);
    }
  }

  async function fetchAnnualBudgetHistoryData() {
    try {
      const response = await fetchWithTimeout(
        DB_URL + "/api/admin/annual-budget/export-history",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
        }
      );
      sessionExpirationChecker(response, logoutUser);
      let result = await response.blob();

      if (result) {
        saveAs(result, `Annual Budget History ${getDateAndTime()}.csv`);
      }
    } catch (error) {
      // console.log(error);
      toast.error(
        "Could not receive Annual Budget History data. Please retry later."
      );
    } finally {
      setIsAnnualBudgetHistoryLoading(false);
    }
  }

  async function fetchLog() {
    try {
      const response = await fetchWithTimeout(DB_URL + "/api/auth/logs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: "Bearer " + user.token,
        },
      });
      sessionExpirationChecker(response, logoutUser);
      let result = await response.blob();

      if (result) {
        saveAs(result, `MySales Log ${getDateAndTime()}.log`);
      }
    } catch (error) {
      // console.log(error);
      toast.error("Could not receive Log data. Please retry later.");
    } finally {
      setIsLogLoading(false);
    }
  }

  return (
    <div
      style={{
        background: "#00000050",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
      }}
    >
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
      <dialog open={isModalOpen} className={styles.dialog}>
        <div className={styles.dialogHeader}>
          <h2 className={styles.dialogTitle}>Export Data</h2>
          <Button
            onClick={() => setIsModalOpen(false)}
            name="close"
            type="button"
            color="default"
            minWidth="auto"
          >
            <CloseIcon />
          </Button>
        </div>

        <div className={styles.dialogBody}>
          <Button
            onClick={() => {
              setIsOrderIncomeLoading(true);
              fetchOrderIncomeData();
            }}
            name="downloadOrderIncome"
            color={"default"}
          >
            {isOrderIncomeLoading && <ButtonAnimation />}
            <p>Download Order Income</p>
          </Button>

          <Button
            onClick={() => {
              setIsOrderIncomeHistoryLoading(true);
              fetchOrderIncomeHistoryData();
            }}
            name="downloadOrderIncomeHistory"
            color={"default"}
          >
            {isOrderIncomeHistoryLoading && <ButtonAnimation />}
            <p>Download Order Income History</p>
          </Button>

          <Button
            onClick={() => {
              setIsLargeOrdersHistoryLoading(true);
              fetchLargeOrderHistoryData();
            }}
            name="downloadLargeOrdersHistory"
            color={"default"}
          >
            {isLargeOrdersHistoryLoading && <ButtonAnimation />}
            <p>Download Large Order Potential History</p>
          </Button>

          <Button
            onClick={() => {
              setIsAnnualBudgetHistoryLoading(true);
              fetchAnnualBudgetHistoryData();
            }}
            name="downloadAnnualBudgetHistory"
            color={"default"}
          >
            {isAnnualBudgetHistoryLoading && <ButtonAnimation />}
            <p>Download Annual Budget History</p>
          </Button>

          <Button
            onClick={() => {
              setIsLogLoading(true);
              fetchLog();
            }}
            name="downloadLog"
            color={"default"}
          >
            {isLogLoading && <ButtonAnimation />}
            <p>Download Log</p>
          </Button>
        </div>
      </dialog>
    </div>
  );
}
