import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { useEffect, useState, useContext } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import styles from "./RecordPanel.module.css";
import { Button } from "../../style/ComponentsLibrary";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import DB_URL from "../../environments";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { sessionExpirationChecker } from "../../utils/fetch_helpers";
import { useCloseRecordPanel } from ".";

function RecordPanelSync() {
  const [data, setData] = useState(null);
  const { user, logoutUser } = useContext(UserContext);
  const label = "Sync Errors List";
  const { recordPanelData } = useContext(LayoutContext);
  const { closePanel } = useCloseRecordPanel();

  useEffect(() => {
    async function getCRMErrors() {
      try {
        let response = await fetch(DB_URL + "/api/crm-sync/search-sync-error", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + user.token,
          },
          body: JSON.stringify({
            crmSyncId: recordPanelData?.id,
          }),
        });
        sessionExpirationChecker(response, logoutUser);
        let result = await response.json();
        if (result) {
          setData(result.content);
        } else {
        }
      } catch (err) {
        // if (process.env.NODE_ENV === "development") throw err
      }
    }
    getCRMErrors();
  }, [recordPanelData, user, logoutUser]);

  return (
    <motion.div
      className={styles.formPanel}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className={styles.fieldsContainer}>
        <div className={styles.panelHeader}>
          <p className={styles.panelLabel} style={{ gap: 10 }}>
            <ErrorOutlineIcon />
            {label}
          </p>

          <div className={styles.buttonGroup}>
            <Button
              name="close"
              type="button"
              color="default"
              minWidth="auto"
              onClick={closePanel}
            >
              <CloseIcon style={{ fill: "currentColor" }} />
            </Button>
          </div>
        </div>
        <div className={styles.inputList}>
          {data && data.length < 1 ? (
            <p>No errors</p>
          ) : (
            data &&
            data.map((item, index, thisArray) => (
              <div key={item.id}>
                <p style={{ color: "gray", margin: "8px 0" }}>{item.syncId}</p>
                <p style={{ margin: "8px 0" }}>{item.details}</p>
                {index === thisArray.length - 1 ? null : (
                  <hr style={{ border: "1px solid lightgray" }} />
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default RecordPanelSync;
