import { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "./UserContext";
import { toast } from "react-toastify";
import DB_URL from "../environments";
import { useLocation } from "react-router-dom";
import { sessionExpirationChecker } from "../utils/fetch_helpers";

const LayoutContext = createContext();

function LayoutContextProvider(props) {
  const { user, logoutUser } = useContext(UserContext);
  const location = useLocation();
  const [showFilters, setShowFilter] = useState(true); // see column filter in tables
  const [showTableGrouping, setShowTableGrouping] = useState(false); // see drag group in table header
  const [showRecordPanel, setShowRecordPanel] = useState(false);
  const [recordPanelType, setRecordPanelType] = useState("");
  const [recordPanelData, setRecordPanelData] = useState("");
  const [settingsBox, setSettingsBox] = useState(false); //default: false
  const [addNewDropdown, setAddNewDropdown] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [headerLSOs, setHeaderLSOs] = useState();
  const [globalSelectedLSO, setGlobalSelectedLSO] = useState(null);
  const [globalSelectedGID, setGlobalSelectedGID] = useState(null);
  const [LSOselectedOption, setLSOSelectedOption] = useState(null);
  const [headerGIDs, setHeaderGIDs] = useState();
  const [showHistoryTable, setShowHistoryTable] = useState();
  const [shouldTableRefresh, setShouldTableRefresh] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [largeOrdersThreshold, setLargeOrdersThreshold] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [preferredFilterValues, setPreferredFilterValues] = useState({
    dashboard: {},
    "order-income": {},
    "large-orders": {},
    "annual-budget": {},
    sync: {},
    users: {},
    lso: {},
    gid: {},
    "sales-regions": {},
    "sub-regions": {},
    territory: {},
    countries: {},
    "win-probability": {},
  });

  useEffect(() => {
    if (localStorage.getItem("showFilters")) {
      setShowFilter(JSON.parse(localStorage.getItem("showFilters")));
    }
  }, []);
  
  useEffect(() => {
    const preferredLSO = localStorage.getItem("preferredLSO"); 

    if (preferredLSO) {
      setGlobalSelectedLSO(parseInt(preferredLSO));
    }  
  }, []);

  useEffect(() => {
    function handlePress(e) {
      if (e.code === "Escape" && showRecordPanel && !showHistoryTable) {
        setShowRecordPanel(false);
      }

      if (e.code === "Escape" && showHistoryTable) {
        setShowHistoryTable(false);
      }
    }
    document.addEventListener("keydown", handlePress, false);
    return () => document.removeEventListener("keydown", handlePress);
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem("showFilters", JSON.stringify(showFilters));
    }
  }, [user, showFilters]);

  function toggleShowFilters() {
    setShowFilter((prevShowFilters) => !prevShowFilters);
  }

  function toggleTableGrouping() {
    setShowTableGrouping((prev) => !prev);
  }

  useEffect(() => {
    successMessage && toast.success(successMessage);
  }, [successMessage]);

  useEffect(() => {
    if (user && location.pathname === "/large-orders") {
      // set threshold to the database:
      async function getThreshold() {
        try {
          const response = await fetch(
            DB_URL + "/api/admin/setting/largeOrdersThreshold",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + user.token,
              },
            }
          );
          sessionExpirationChecker(response, logoutUser);
          const result = await response.json();
          result && setLargeOrdersThreshold(result.value);
        } catch (err) {
          // if (process.env.NODE_ENV === "development") throw err
        }
      }
      getThreshold();
    }
  }, [location.pathname, user, logoutUser]);

  return (
    <LayoutContext.Provider
      value={{
        showFilters,
        toggleShowFilters,
        showTableGrouping,
        setShowTableGrouping,
        toggleTableGrouping,
        showRecordPanel,
        setShowRecordPanel,
        recordPanelType,
        setRecordPanelType,
        recordPanelData,
        setRecordPanelData,
        settingsBox,
        setSettingsBox,
        addNewDropdown,
        setAddNewDropdown,
        mobileMenu,
        setMobileMenu,
        globalSelectedGID,
        setGlobalSelectedGID,
        globalSelectedLSO,
        setGlobalSelectedLSO,
        LSOselectedOption,
        setLSOSelectedOption,
        showHistoryTable,
        setShowHistoryTable,
        headerLSOs,
        setHeaderLSOs,
        headerGIDs,
        setHeaderGIDs,
        shouldTableRefresh,
        setShouldTableRefresh,
        successMessage,
        setSuccessMessage,
        largeOrdersThreshold,
        setLargeOrdersThreshold,
        headerHeight,
        setHeaderHeight,
        preferredFilterValues,
        setPreferredFilterValues,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
}

export { LayoutContextProvider, LayoutContext };
