import { useState, useEffect } from "react"
import DB_URL from "../environments"

export default function ServerStatus () {
  const [contentMessage, setContentMessage] = useState(null)

  const pingServer = async () => {
    console.log("PING")
    try {
      let response = await fetch(DB_URL + "/api/auth/ping", {
        method: "GET"
      })
      let result = await response
      console.log(result)
      if (result.status === 200) {
        setContentMessage("✅ SERVER OK ✅")
      } else if (result.status === 503) {
        setContentMessage("❌ SERVER NOT OK ❌")
      } else {
        setContentMessage("")
      }
    } catch (err) {
      setContentMessage("🔻 SERVER SEEMS DOWN 🔻")
    // if (process.env.NODE_ENV === "development") throw err
    }
  }

  useEffect(() => {
    pingServer()
  }, [])

  return <p style={{ textAlign: "center" }}>{contentMessage}</p>
}
